window.CONST_ADMIN_ROLE_SUPER_ADMIN = 1
window.CONST_ADMIN_ROLE_ADMIN = 2
window.CONST_ADMIN_ROLE_STAFF = 3
window.CONST_SUPREME_SUPER_ADMIN_ID = 1
window.CONST_HTTP_STATUS_CODE_ERROR_AUTHENTICATION = 401
window.CONST_HTTP_STATUS_CODE_METHOD_NOT_ALLOWED = 405

window.CONST_LOCAL_STORAGE_KEY_ADMIN_INFO = 'adminInfo'
window.CONST_LOCAL_STORAGE_KEY_API_TOKEN = 'apiToken'
window.CONST_LOCAL_STORAGE_KEY_ORIGIN_ADMIN_INFO = 'orgAdminInfo'
window.CONST_LOCAL_STORAGE_KEY_CHAT_AUTH_TOKEN = 'chatAuthToken'
window.CONST_LOCAL_STORAGE_KEY_ORIGIN_API_TOKEN = 'orgApiToken'
window.CONST_LOCAL_STORAGE_KEY_ORIGIN_CURRENT_MENU = 'orgCurrentMenu'

window.MENU_REFRESH_NUMBER = 1

window.CONST_DB_TABLE = {
  QualificationQuestion: {
    Usage: [
      {
        id: 1,
        name: 'Supplier Questions'
      },
      {
        id: 2,
        name: 'System Questions'
      },
      {
        id: 3,
        name: 'Dummy Questions'
      }
    ],
    USAGE_SUPPLIER: 1,
    USAGE_SYSTEM: 2,
    USAGE_DUMMY: 3
  }
}

window.PRIME_OPINION = {
  // Please also update TransactionStatusEnum.php when update transactionTypes
  transactionTypes: [
    {text: 'Success', value: 1},
    {text: 'Screenout', value: 2},
    {text: 'Plugin installed bonus', value: 6},
    {text: 'Claim', value: 100},
    {text: 'Claim Invert', value: 105},
    {text: 'Claim Refund Auto', value: 110},
    {text: 'Claim Refund Manual', value: 115},
    {text: 'Bonus', value: 200},
    {text: 'Bonus Prize Draw', value: 210},
    {text: 'Bonus Level Up', value: 220},
    {text: 'Bonus Referral', value: 230},
    {text: 'Bonus Referral Invert', value: 235},
    {text: 'Leaderboard Bonus', value: 240},
    {text: 'Bonus One Click Survey', value: 245},
    {text: 'Bonus Achievement', value: 250},
    {text: 'Bonus Goodwill', value: 255},
    {text: 'Manual', value: 300}
  ],

  triggerEventTypes: [
    {text: 'SOI', value: 'soi'},
    {text: 'DOI', value: 'doi'},
    {text: 'Profiler Complete', value: 'profiler_complete'},
    {text: 'First Survey Started', value: 'first_survey_started'},
    {text: 'First Survey Complete', value: 'first_survey_complete'}
  ],

  getTransactionTypeByValue: (value) => {
    const status = window.PRIME_OPINION.transactionTypes.find(status => status.value === value)
    return status?.text
  }
}
