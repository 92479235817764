<template>
  <table class="table b-table table-striped table-hover" v-if="emailDomainFrauds">
    <thead>
    <tr>
      <th>User</th>
      <th>App</th>
      <th>Time</th>
      <th>E-Mail</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="emailDomainFraud in emailDomainFrauds" :key="emailDomainFraud.id">
      <td>
        <router-link v-if="canAccess('user_details')"
                     :to="{
            name: 'user_details',
            params: {
                userId: emailDomainFraud.id,
            },
          }"
        >
          <UserElement :id="emailDomainFraud.id" :status="emailDomainFraud.status"/>
        </router-link>
        <span v-else>{{ emailDomainFraud.id }}</span>
      </td>
      <td>{{ emailDomainFraud.app_name }}</td>
      <td>{{ emailDomainFraud.time }}</td>
      <td>{{ emailDomainFraud.email }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    emailDomainFrauds: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style>

</style>
