import itemSchema from '@/store/modules/schemas/user.schema'
import ModuleBase from '@/store/modules/ModuleBase'
import axios from '@/main/axios'
import {isInteger} from 'lodash'

const resourceName = 'users'
const base = ModuleBase(itemSchema, resourceName)
export const ReviewStatus = {
  NEW: 0,
  PROBLEM: 1,
  REVIEW: 2,
  GOOD: 3,
  PERFECT: 4
}
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
    authenticatedUser: null,
    reviewStatus: {
      0: 'new',
      1: 'problem',
      2: 'review',
      3: 'good',
      4: 'perfect'
    },
    countUserNeedReview: 0,
    currentUserDetails: null,
    shouldRefreshReviewTable: false
  },
  getters: {
    ...base.getters,
    authenticatedUser: state => state.authenticatedUser,
    getByHash: state => userHash => {
      const user = state.all.filter(u => u.hash === userHash)
      return user.length ? user[0] : {}
    },
    getReviewStatusText: state => statusId => {
      if (!isInteger(statusId)) statusId = parseInt(statusId)
      return state.reviewStatus[statusId]
    },
    countUserNeedReview: state => state.countUserNeedReview,
    getCurrentUserDetails: state => state.currentUserDetails
  },
  mutations: {
    ...base.mutations,
    SET_AUTHENTICATED_USER (state, payload) {
      state.authenticatedUser = payload
    },
    SET_COUNT_USER_NEED_REVIEW (state, count) {
      state.countUserNeedReview = count
    },
    SET_CURRENT_USER_DETAIL (state, currentUserDetails) {
      state.currentUserDetails = currentUserDetails
    },
    TOGGLE_REFRESH_REVIEWS_TABLE (state) {
      state.shouldRefreshReviewTable = !state.shouldRefreshReviewTable
    }
  },
  actions: {
    ...base.actions,
    setAuthenticatedUser: async ({commit}, payload) => {
      return commit('SET_AUTHENTICATED_USER', payload)
    },
    getStatisticsData: async ({commit}, userId) => {
      return await axios.get(`${base.basePath + resourceName  }/get-statistics-data/${ userId }`)
    },
    getCustomStatisticsData: async ({commit}, {userId, statisticRelations}) => {
      return await axios.get(`${base.basePath + resourceName  }/get-statistics-data/${ userId }`,
        {params: {statisticRelations}}
      )
    },
    updateReviewStatus: async ({commit}, userData) => {
      return await axios.post(`${base.basePath + resourceName  }/update-review-status`, userData)
    },
    getListUsersToReview: async () => {
      return await axios.post(`${base.basePath + resourceName  }/get-list-user-to-review`)
    },
    countUserNeedReview: async ({commit}) => {
      const res = await axios.post(`${base.basePath + resourceName  }/count-user-need-review`)
      const count = res.data ? res.data.data : 0
      return commit('SET_COUNT_USER_NEED_REVIEW', count)
    },
    getUsersToReview: async () => {
      return await axios.post(`${base.basePath + resourceName  }/get-user-to-review`)
    },
    getByHash: async ({commit}, requestData) => {
      const res = await axios.get(`${base.basePath + resourceName  }/get-by-hash/${  requestData.hash}`, {params: requestData.params})
      const item = res.status === 200 && res.data ? res.data : null
      if (!item) return null
      //find item in list state and update it
      await commit('UPSERT', item)
      return item
    }
  }
}
