<template>
  <div>
    <template v-if="elementSettings.sub_type !== 'hidden'">
        <div style="display: flex;align-items: center;gap: 5px;">
            <b-form-input
                :class="errorClasses"
                v-model="elementSettings.value"
                :type="(typeof elementSettings.sub_type === 'string' ? elementSettings.sub_type : 'text')"
                :style="typeof elementSettings.controlStyle === 'string' ? elementSettings.controlStyle : ''"
                :placeholder="typeof elementSettings.place_holder === 'string' ? elementSettings.place_holder : ''" 
                :readonly="elementSettings.readonly"
                :disabled="elementSettings.disabled"
                @input="inputEvent"
            ></b-form-input>
            <div v-if="elementSettings.next_text">{{ elementSettings.next_text }}</div>
      </div>
      <div :style="typeof elementSettings.errorStyle === 'string' ? elementSettings.errorStyle: ''" class="text-error-notification"
           v-if="isError && typeof elementSettings.errorMessage === 'string' && elementSettings.isValidated">{{ elementSettings.errorMessage }}
      </div>
    </template>
    <template v-else>
      <input type="hidden" v-model="elementSettings.value"/>
    </template>
  </div>
</template>

<script>
import {BCol, BFormInput, BRow} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput
  },
  props: {
    elementSettings: {
      required: true,
      type: Object
    },
    formSettings: {
      required: true,
      type: Object
    }
  },
  methods: {
    executeElementValidate () {
      this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
    },
    inputEvent ($event) {
      if (this.elementSettings.event && typeof this.elementSettings.event.input === 'function') {
        this.elementSettings.event.input($event, this.elementSettings, this.formSettings)
      }
    }
  },
  computed: {
    isError: {
      cache: false,
      get () {
        this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
        return this.elementSettings.isError
      }
    },
    errorClasses: {
      cache: false,
      get () {
        if (typeof this.elementSettings.errorClasses === 'string') {
          if (this.elementSettings.isValidated && this.isError) {
            return this.elementSettings.errorClasses
          }
          return ''
        } else {
          return ''
        }
      }
    }
  }
}
</script>
