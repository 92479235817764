import router from '@/main/router.js'

export default {
  namespaced: true,
  actions: {
    startAutoRefresh: ({ dispatch, commit }) => {
      if (router.canAccessPage('click_tickets')) {
        setInterval(() => {
          dispatch('click_ticket/getNotReviewedCount', null, { root: true })
        }, 30000) // 30 seconds
      }

      if (router.canAccessPage('system_alerts')) {
        setInterval(() => {
          dispatch('system_alert/getNotSeenCount', null, {root: true})
        }, 60000)
      }

      if (router.canAccessPage('user_reviews')) {
        setInterval(() => {
          dispatch('user/countUserNeedReview', null, {root: true})
          commit('user/TOGGLE_REFRESH_REVIEWS_TABLE', null, {root: true})
        }, 30000)
      }

      if (router.canAccessPage('chat_dashboard')) {
        setInterval(() => {
          commit('chat/TOGGLE_REFRESH_DASHBOARD_TABLE', null, {root: true})
        }, 60000)
      }
    }
  }
}
