export default {
  methods: {
    findRefByName (refName) {
      let obj = this
      while (obj) {
        if (obj.$refs[refName]) return obj.$refs[refName]
        obj = obj.$parent
      }
      return undefined
    },
    UUID () {
      let dt = new Date().getTime()
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line no-mixed-operators
        const r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        // eslint-disable-next-line no-mixed-operators
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
    },
    sendActionLog (action, details = null) {
      const fullUrl = window.location.href

      this.$http.post('/api/admin-logs', {
        type: 'action',
        action,
        request_url: fullUrl,
        data: details
      })
        .catch(error => {
          console.error('Error logging page action:', error)
        })
    },
    generateChartActions () {
      const _this = this

      const chartBodies = document.querySelectorAll('.p-chart-body')

      chartBodies.forEach((chartBody) => {
        const title = chartBody.previousElementSibling.textContent
        const exportMenuItems =  chartBody.querySelectorAll('.apexcharts-menu-item')

        if (exportMenuItems.length >= 1) {
          const [buttonSVG, buttonPNG, buttonCSV] = exportMenuItems
          buttonSVG.addEventListener('click', () => {
            _this.sendActionLog(`Export SVG ${title} chart from ${_this.$route.name}`)
          })

          buttonPNG.addEventListener('click', () => {
            _this.sendActionLog(`Export PNG ${title} chart from ${_this.$route.name}`)
          })

          buttonCSV.addEventListener('click', () => {
            _this.sendActionLog(`Export CSV ${title} chart from ${_this.$route.name}`)
          })
        }
      })
    },
    invokeMethod (methodName) {
      if (typeof this[methodName] === 'function') {
        this[methodName]()
      }
    }
  },
  computed: {
    isQA () {
      const currentUserId = this.$store.state.auth.AppActiveAdmin.id

      return [11, 46].includes(currentUserId)
    },
    isTopAdmin () {
      const currentUserId = this.$store.state.auth.AppActiveAdmin.id

      return [1, 5].includes(currentUserId)
    }
  },
  created () {
    if (!window.VueComp) window.VueComp = {
      Others: []
    }

    const compName = this.$options.name
    if (typeof compName === 'undefined') return
    if (!window.VueComp[compName]) window.VueComp[compName] = this
    else window.VueComp.Others.push(this)
  }
}
