import itemSchema from '@/store/modules/schemas/default.schema'
import ModuleBase from '@/store/modules/ModuleBase'
import axios from '@/main/axios'

const resourceName = 'system-alerts'
const base = ModuleBase(itemSchema, resourceName)
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
    ...{
      notSeenCount: 0
    }
  },
  getters: {
    ...base.getters,
    notSeenCount: state => state.notSeenCount
  },
  mutations: {
    ...base.mutations,
    SET_NOT_SEEN_COUNT (state, value) {
      state.notSeenCount = value
    }
  },
  actions: {
    ...base.actions,
    getNotSeenCount: async (state) => {
      const url = `${ base.basePath + resourceName }/not-seen-count`
      const { data } = await axios.get(url)
      state.commit('SET_NOT_SEEN_COUNT', data.count)
    },
    markAsSeen: async (state, id) => {
      const url = `${ basePath + resourceName }/${id}`
      await axios.put(url, { is_seen: true })
      state.dispatch('getNotSeenCount')
    }
  }
}
