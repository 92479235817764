<template>
  <table class="table b-table table-striped table-hover">
    <thead>
    <tr>
      <th>Question</th>
      <th>Answer</th>
      <th>Answer Input</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="userProfilingBasic in userProfilingBasics" :key="userProfilingBasic.question_id">
      <td>
        <router-link v-if="userProfilingBasic.question_id && canAccess('question_details')" :to="{ name: 'question_details', params: { questionId: userProfilingBasic.question_id }}">
          #{{ userProfilingBasic.question_id }}
        </router-link>
        <span v-else>#{{ userProfilingBasic.question_text }}</span>

        {{ userProfilingBasic.question_text }}
      </td>
      <td>{{ userProfilingBasic.answer_text }}</td>
      <td>{{ userProfilingBasic.answer_input }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    userProfilingBasics: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style>

</style>
