<template>
  <multiselect
      :style="{ width: computedWidth }"
      class="my-1"
      v-model="localSelectedOptions"
      :track-by="trackBy"
      :label="label"
      :loading="isLoading"
      :options="options"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :placeholder="placeholderText"
      :show-labels="false"
      :internal-search="searchUrl === ''"
      @search-change="searchUrl !== '' && updateSearch()"
  >
    <template v-if="label === ''" v-slot:selection="{ values, search, isOpen }">
      <span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} {{ labelSelectedText }}</span>
    </template>
    <template v-slot:tag="{ option, remove }">
      <span class="custom__tag d-flex">
        <slot name="multi-select__selected" :option="option">
          <span>{{ option[trackBy] }}</span>
        </slot>

        <span class="custom__remove ml-25 cursor-pointer" @click.stop="remove(option)">❌</span>
      </span>
    </template>
    <template v-slot:option="data">
      <div class="option__desc d-flex align-items-center">
        <FeatherIcon v-if="localSelectedOptions.includes(data.option)" icon="CheckIcon" size="16" class="bg-primary text-white rounded p-25" />
        <FeatherIcon v-else icon="SquareIcon" size="16" />

        <slot name="multi-select__text" :option="data.option">
          <span class="ml-50 option__title">{{ data.option }}</span>
        </slot>
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'MultiSelectForm',
  components: {
    Multiselect
  },
  props: {
    defaultOptions: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    placeholderText: {
      type: String,
      default: ''
    },
    labelSelectedText: {
      type: String,
      default: 'items selected'
    },
    searchUrl: {
      type: String,
      default: ''
    },
    trackBy: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false,
      search: '',
      options: [...this.defaultOptions]
    }
  },
  watch: {
    search (query) {
      this.searchOptionsViaAPI(query)
    },
    defaultOptions (newOptions) {
      this.options = [...newOptions]
    }
  },
  computed: {
    localSelectedOptions: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    computedWidth () {
      const baseWidth = 40

      if (this.label === '') {
        return baseWidth
      }
      
      const incrementWidth = 10
      return `${baseWidth + this.localSelectedOptions.length * incrementWidth}%`
    }
  },
  methods: {
    updateSearch (query) {
      this.search = query
    },
    async searchOptionsViaAPI (query) {
      this.isLoading = true

      const res = await this.$http.get(this.searchUrl, {
        params: {
          search: query,
          itemPerPage: 20
        }
      })

      this.options = res.data.data
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
@import "../../@core/scss/vue/libs/vue-multiselect.scss";

.multiselect__tags-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.dark-layout .multiselect__tags-wrap {
  color: white;
}

</style>