<template>
<b-row>
  <b-col cols="6"><flat-pickr :config="configFromdateTimePicker" class="form-control" v-model="fromDate" placeholder="From Date" @on-change="onFromChange" /></b-col>
  <b-col cols="6"><flat-pickr :config="configTodateTimePicker" class="form-control" v-model="toDate" placeholder="To Date" @on-change="onToChange" /></b-col>
</b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BRow,
    BCol
  },
  props: ['value', 'enableTime'],
  data () {
    return {
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: this.enableTime ? { enableTime: true } : {},
      configTodateTimePicker: this.enableTime ? { enableTime: true } : {}
    }
  },
  methods: {
    onFromChange (selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, 'minDate', dateStr)

      setTimeout(() => {
        this.$emit('input', {
          fromDate: this.fromDate,
          toDate: this.toDate
        })
      }, 500)
    },
    onToChange (selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, 'maxDate', dateStr)

      setTimeout(() => {
        this.$emit('input', {
          fromDate: this.fromDate,
          toDate: this.toDate
        })
      }, 500)
    }
  },
  mounted () {
    if (typeof this.value === 'object') {
      const fromDate = this.value.fromDate
      const toDate = this.value.toDate

      this.configFromdateTimePicker.maxDate = toDate
      this.configTodateTimePicker.minDate = fromDate
      this.fromDate = fromDate
      this.toDate = toDate
      this.toDate = this.value.toDate
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
