import itemSchema from '@/store/modules/schemas/default.schema'
import ModuleBase from '@/store/modules/ModuleBase'
import axios from '@ps_main/axios.js'

const resourceName = 'qualification_answers'
const base = ModuleBase(itemSchema, resourceName)
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state
  },
  getters: {
    ...base.getters
  },
  mutations: {
    ...base.mutations
  },
  actions: {
    ...base.actions,
    mapCombinedAnswers: async ({commit}, items) => {
      const res = await axios.post(`${base.basePath + resourceName  }/map-combined-answers`, items)
      if (res.status === 200 && res.data && res.data.data) {
        console.log('list updated combined answers:', res.data.data)
      }
      return res
    }
  }
}
