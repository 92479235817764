<template>
  <div v-if="isMounted">
        <b-form-radio-group
            v-model="elementSettings.value" 
            :name="'radio_' + elementSettings.post_param + '_' + uuid" 
            :class="errorClasses" 
        >
            <b-form-radio v-for="(item, idx) in elementSettings.data" :key="idx" :value="item.value">{{item.text}}</b-form-radio>
        </b-form-radio-group>
        <div class="text-error-notification" v-if="isError && typeof elementSettings.errorMessage === 'string' && elementSettings.isValidated">{{elementSettings.errorMessage}}</div>
  </div>
</template>

<script>
import {BFormRadioGroup, BFormRadio} from 'bootstrap-vue'
export default {
  components: {
    BFormRadioGroup, 
    BFormRadio
  },
  props: {
    elementSettings: {
      required: true,
      type: Object
    },
    formSettings: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      uuid: '',
      isMounted: false
    }
  },
  methods: {
    executeElementValidate () {
      this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
    }
  },
  computed: {
    isError: {
      cache: false,
      get () {
        this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
        return this.elementSettings.isError
      }
    },
    errorClasses: {
      cache: false,
      get () {
        if (typeof this.elementSettings.errorClasses === 'string') {
          if (this.elementSettings.isValidated && this.isError) {
            return this.elementSettings.errorClasses
          }
          return ''
        } else {
          return ''
        }
      }
    }
  },
  created () {
    this.uuid = this.uuidv4()
  },
  mounted () {
    this.isMounted = true
  }
}
</script>