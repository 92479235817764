import itemSchema from '@/store/modules/schemas/default.schema'
import ModuleBase from '@/store/modules/ModuleBase'
import axios from '@ps_main/axios.js'

const resourceName = 'surveys'
const base = ModuleBase(itemSchema, resourceName)
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state
  },
  getters: {
    ...base.getters,
    getSurveyById: state => surveyId => {
      const survey = state.all.filter(s => parseInt(s.id) === parseInt(surveyId))
      if (survey.length) return survey[0]
      return null
    }
  },
  mutations: {
    ...base.mutations
  },
  actions: {
    ...base.actions,
    getSurveyById: async ({commit}, requestData) => {
      const withStr = requestData.params.with.replace(/ /g, '').replace(/(?:\r\n|\r|\n)/g, '')
      const withCountStr = requestData.params.withCount.replace(/ /g, '').replace(/(?:\r\n|\r|\n)/g, '')
      const res = await axios.get(`${base.basePath + resourceName  }/${  requestData.id}`,
        {
          params: {
            with: withStr,
            withCount: withCountStr
          }
        })
      const item = res.status === 200 && res.data ? res.data : null
      if (!item) return null
      //find item in list state and update it
      await commit('UPSERT', item)
      return item
    },
    getStatisticsData: async ({commit}, surveyId) => {
      const res = await axios.get(`${base.basePath + resourceName  }/${  surveyId}`)
      return res.status === 200 && res.data ? res.data : null
    }
  }
}
