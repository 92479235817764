//Sub permission: check \App\Lib\AdminPermissions.php
import routerConfigs from '../router-configs/psaRouterConfigs'

export default [
  {
    title: 'P Sample',
    icon: 'CopyIcon',
    children: [
      {
        url: routerConfigs.PSA_BUYERS.path,
        title: 'Buyers',
        icon: 'DollarSignIcon',
        route: routerConfigs.PSA_BUYERS.name,
        sub_permissions: [
          {
            id: routerConfigs.PSA_BUYERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PSA_SURVEYS.path,
        title: 'Surveys',
        icon: 'LayoutIcon',
        route: routerConfigs.PSA_SURVEYS.name,
        sub_permissions: [
          {
            id: routerConfigs.PSA_SURVEYS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PSA_MONTHLY_INVOICING.path,
        title: 'Monthly Invoicing',
        icon: 'ToolIcon',
        route: routerConfigs.PSA_MONTHLY_INVOICING.name,
        sub_permissions: [
          {
            id: routerConfigs.PSA_MONTHLY_INVOICING.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PSA_SURVEY_PO_INVOICING.path,
        title: 'Survey/PO Invoicing',
        icon: 'PercentIcon',
        route: routerConfigs.PSA_SURVEY_PO_INVOICING.name,
        sub_permissions: [
          {
            id: routerConfigs.PSA_SURVEY_PO_INVOICING.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  }
]
