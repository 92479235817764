import itemSchema from '@/store/modules/schemas/default.schema'
import ModuleBase from '@/store/modules/ModuleBase'
import axios from '@ps_main/axios.js'

const resourceName = 'countries'
const base = ModuleBase(itemSchema, resourceName)
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state
  },
  getters: {
    ...base.getters
  },
  mutations: {
    ...base.mutations
  },
  actions: {
    ...base.actions,
    getSurveyCountriesAndLanguages: async ({commit}) => {
      const res = await axios.get(`${base.basePath + resourceName  }/survey-countries-languages`)
      const item = res.status === 200 && res.data ? res.data : null
      if (!item) return null
      //find item in list state and update it
      return item
    }
  }
}
