/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from './../store/index'
import PagesAndPermissions from './allPagesAndPermissions.js'
import baseRouterConfigs from './router-configs/baseRouterConfigs'
import poRouterConfigs from './router-configs/poRouterConfigs'
import fsRouterConfigs from './router-configs/fsRouterConfigs'
import hcRouterConfigs from './router-configs/hcRouterConfigs'
import psaRouterConfigs from './router-configs/psaRouterConfigs'


Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {x: 0, y: 0}
  },
  routes: Object.values({
    ...baseRouterConfigs,
    ...poRouterConfigs,
    ...fsRouterConfigs,
    ...hcRouterConfigs,
    ...psaRouterConfigs
  })
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['auth/isLoggedIn']
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn) {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    } else if (router.canAccessPage(to.name)) {
      // quick links bar
      // eslint-disable-next-line no-use-before-define
      manageQuickLinks(to)
      next()
    } else {
      router.redirectToMainPage(`router.beforeEach ${  to.name}`)
      return false
    }
  } else if (
    to.matched.some(record => record.meta.redirectOnLogin) &&
      isLoggedIn
  ) {
    next({
      path: '/'
    })
  } else {
    next()
  }
})

router.canAccessPage = (pageName) => {
  const adminInformation = store.getters['auth/admin']

  if (!adminInformation) {
    location.reload(true)
  }
  const adminId = adminInformation.id
  const superAdminPermissionModules = PagesAndPermissions.flatMap(({ always_available_for_ids, sub_permissions, route }) => {
    const subIds = sub_permissions
      ?.filter(({ always_available_for_ids: subAlwaysAvailableForIds }) => !subAlwaysAvailableForIds)
      ?.map(({ id }) => id)

    const id = always_available_for_ids ? null : route

    return [...subIds, id].filter(id => id) // Return valid IDs only
  })

  const hardCodePermissionModules = PagesAndPermissions.flatMap(({ always_available_for_ids, sub_permissions, route }) => {
    const subIds = sub_permissions
      ?.filter(({ always_available_for_ids: subAlwaysAvailableForIds }) => (subAlwaysAvailableForIds?.length ? subAlwaysAvailableForIds.includes(adminId) : false) // If `always_available_for_ids` is not defined or empty, include it
      )
      .map(({ id }) => id)

    const id = always_available_for_ids?.length ? (always_available_for_ids.includes(adminId) ? route : null) : null // If `always_available_for_ids` is empty or undefined, return the menu item

    return [...subIds, id].filter(id => id) // Return valid IDs only
  })

  if (
    ((adminInformation.role === window.CONST_ADMIN_ROLE_SUPER_ADMIN) && superAdminPermissionModules.includes(pageName)) ||
    (adminInformation.permissions.modules instanceof Array && adminInformation.permissions.modules.indexOf(pageName) >= 0) ||
    hardCodePermissionModules.includes(pageName)
  ) return true
}

router.redirectToMainPage = (reason) => {
  // console.log(`router.redirectToMainPage ${  reason}`)
  const adminInformation = store.getters['auth/admin']
  if (adminInformation) {
    //We need to store main_page for each admin
    //   console.log(adminInformation.permissions.main_page)
    const adminMainPage = typeof adminInformation.permissions.main_page === 'string' ? adminInformation.permissions.main_page : null

    //If we found main page, we redirect to main page
    let filterPages = PagesAndPermissions.filter((item) => {
      const mainPages = item.sub_permissions.filter((p) => {
        return p.main === true
      })
      return mainPages[0] && mainPages[0].id === adminMainPage
    })
    if (filterPages.length > 0) {
      router.push(router.getRouteWithName(filterPages[0].route))
      return
    }

    //If we didn't find out main page, if role is super admin, we redirect to the first page
    //It's because admin can access all the pages
    if (adminInformation.role === window.CONST_ADMIN_ROLE_SUPER_ADMIN) {
      router.push(router.getRouteWithName(PagesAndPermissions[0].route))
      return
    }

    const allAccessedModules = adminInformation.permissions.modules
    filterPages = PagesAndPermissions.filter((item) => {
      for (let i = 0; i < item.sub_permissions.length; i++) {
        if (
          item.sub_permissions[i].main === true &&
          allAccessedModules.indexOf(item.sub_permissions.id) >= 0
        ) {
          return true
        }
      }
      return false
    })
    if (filterPages.length > 0) {
      router.push(router.getRouteWithName(filterPages[0].route))
      return
    }

    throw new Error('Admin settings is wrong. Check router.js')
  } else {
    throw new Error('Admin information is wrong')
  }
}

router.getRouteWithName = (routerName) => {
  let listRouters = []
  router.options.routes.forEach(route => {
    if (route.children instanceof Array) {
      listRouters = listRouters.concat(route.children)
    } else {
      listRouters.push(route)
    }
  })
  const filterRouters = listRouters.filter(function (item) {
    return item.name === routerName
  })
  if (filterRouters.length === 1) return filterRouters[0]
  throw new Error('2 or more routers with the same name')
}

function sendViewLog (fullIrl) {
  window.PRIME_VUE_OBJECT.$http.post('/api/admin-logs', {
    type: 'view',
    request_url: fullIrl
  })
    .catch(error => {
      console.error('Error logging page view:', error)
    })
}

function manageQuickLinks (to) {
  const isQuickLink = to.meta?.quickLink
  const models = to.meta?.models || ['user']

  const modelFilterKeys = {
    user: ['filterUserId', 'filter[userId]'],
    survey: ['filterSurveyId', 'filter[surveyId]']
    // Additional models can be added here
  }

  if (isQuickLink) {
    const mainQuickLink = to.meta?.main
    const quickLinkProperties = store.getters['app/quickLinkProperties']

    if (mainQuickLink) {
      const model = models[0]
      const idParam = `${model}Id` // Dynamically use `modelId` pattern for ID retrieval
      const id = to.params[idParam]

      store.commit('app/SET_QUICK_LINK_PROPERTIES', {
        display: true,
        project: to.meta.project,
        id,
        model
      })
      store.commit('app/SET_QUICK_LINK_MODAL_STATUS', false)
    } else if (quickLinkProperties?.display) {
      const queryKeys = Object.keys(to.query)
      const model = quickLinkProperties?.model
      const modelFilterKeysForModel = modelFilterKeys[model] || []
      const hasQueryIntersection = modelFilterKeysForModel.some(key => queryKeys.includes(key))

      if (!hasQueryIntersection) {
        store.commit('app/SET_QUICK_LINK_PROPERTIES', {
          display: false,
          id: null,
          project: null,
          model: 'user'
        })
      }
    }
  } else {
    // Reset quick link properties if not a quick link route
    store.commit('app/SET_QUICK_LINK_PROPERTIES', {
      display: false,
      id: null,
      project: null,
      model: 'user'
    })
  }
}


router.afterEach((page) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  const fullUrl = new URL(page.fullPath, window.location.origin).href

  if (page.path !== '/login') {
    setTimeout(() => sendViewLog(fullUrl), 1000)
  }
})

export default router
