<template>
  <div class="country-text" v-if="!withUpdateCountry">
    <div>
      <country-flag :country="code" size="normal" />
    </div>
    <div>{{ name }}</div>
  </div>

  <b-dropdown
      v-else
      @shown="$refs[`country-options-${user.id}`].activate()"
      variant="link"
      toggle-class="text-decoration-none pl-0 text-secondary"
      no-caret
      :ref="`dropdown-country-${user.id}`"
  >
    <template #button-content>
      <div class="country-text">
        <div>
          <country-flag :country="code" size="normal" />
        </div>
        <div>{{ name }}</div>
      </div>
    </template>

    <b-dropdown-text style="width: 300px;" class="country-list">
      <multiselect
          class="mb-1"
          :options="countries"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          :show-labels="false"
          :ref="`country-options-${user.id}`"
          @select="updateCountry(user.id, $event)"
          placeholder="Select country to update"
          label="name"
          track-by="name"
      >
        <template slot="option" slot-scope="props">
          <div class="option__desc d-flex">
            <div class="country-text">
              <div>
                <country-flag :country="props.option.code" size="normal" />
              </div>
              <div>{{ props.option.name }}</div>
            </div>
          </div>
        </template>
      </multiselect>
    </b-dropdown-text>
  </b-dropdown>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {Multiselect},
  props: {
    code: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    withUpdateCountry: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default () {
        return {}
      }
    },
    countries: {
      type: Array,
      default () {
        return []
      }
    },
    url: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateCountry (id, selectedOption) {
      this.$http.put(
        this.url,
        {
          country_code: selectedOption.code
        }
      ).then(() => {
        this.$emit('updated', selectedOption)

        this.alertSuccess('Update country successfully!')
      }).catch((errors) => {
        if (errors && errors.response && errors.response.data) {
          this.alertError(errors.response.data.errors)
        }
      })

      this.$refs[`dropdown-country-${id}`].hide()
    }
  }
}
</script>

<style lang="scss">
  @import "../../@core/scss/vue/libs/vue-multiselect.scss";

  .country-list p.b-dropdown-text {
    padding-right: 5px !important;
    padding-left: 5px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin: 0 !important;
  }
</style>
