<template>
  <table class="table b-table table-striped table-hover" v-if="relatedAccountData">
    <thead>
    <tr>
      <th>User ID</th>
      <th>App</th>
      <th>IP Match</th>
      <th>Account Match</th>
      <th>Pattern Match</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="relatedAccount in relatedAccountData" :key="relatedAccount.id">
      <td>
        <router-link v-if="canAccess('user_details')"
                     :to="{
            name: 'user_details',
            params: {
                userId: relatedAccount.user_id,
            },
          }"
        >
          <UserElement :id="relatedAccount.user_id" :status="relatedAccount.status"/>
        </router-link>
        <span v-else>{{ relatedAccount.user_id }}</span>
      </td>
      <td>
        <div class="brand-logo">
          <b-img
            v-if="relatedAccount.app_name === 'Prime Opinion'"
            :src="primeOpinionLogo"
            alt="prime-opinion-logo"
            width="20px"
          ></b-img>
          <b-img
            v-else-if="relatedAccount.app_name === 'Five Surveys'"
            :src="fiveSurveysLogo"
            alt="five-surveys-logo"
            width="25px"
          ></b-img>
          {{ relatedAccount.app_name }}
        </div>
      </td>
      <td>{{ relatedAccount.match_claim_ip }}</td>
      <td>{{ relatedAccount.match_claim_account }}</td>
      <td>{{ relatedAccount.match_dup_hash }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {$themeConfig} from '@themeConfig';

export default {
  props: {
    relatedAccountData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      primeOpinionLogo: null,
      fiveSurveysLogo: null,
    }
  },
  mounted () {
    this.primeOpinionLogo = $themeConfig.app.appLogoImage
    this.fiveSurveysLogo = $themeConfig.app.fiveSurvesLogoImage
  }
}
</script>

<style>

</style>
