<template>
  <div :key="tooltipTargetId" class="ps-tooltip-parent" @mouseleave="showIconNewTab = false">
    <div :id="tooltipTargetId" @mouseover="canAccess('user_details') && !userDetail ? getBasicDetail() : ''" class="user-item d-flex align-items-center">
      <div class="user-id">
        <a
            v-if="canAccess('user_details')"
            @mouseenter="showIconNewTab = true"
            class="text-primary"
            @click="handleLinkClick"
            :href="userDetailsURL"
        >
          {{ id }}
        </a>
        <span v-else>{{ id }}</span>
      </div>
      <div :class="`user-status user-status-${status}`"></div>

      <div style="width: 30px;">
        <feather-icon
            v-show="showIconNewTab"
            icon="ExternalLinkIcon"
            class="text-primary"
            size="15"
            @click="openUserDetails"
        />
      </div>
    </div>

    <b-tooltip v-if="canAccess('user_details')" :target="tooltipTargetId" :variant="$store.state.appConfig.layout.skin" triggers="hover" placement="bottomright" custom-class="tooltip-user-details">
      <div class="width-350 font-small-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <div class="card p-2 mb-0">
              <div class="d-flex justify-content-between">
                <b-skeleton width="30%"></b-skeleton>
                <b-skeleton width="40%"></b-skeleton>
              </div>

              <div class="d-flex justify-content-between">
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="40%"></b-skeleton>
              </div>
              <b-skeleton width="70%"></b-skeleton>

              <b-skeleton class="mt-3" width="50%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
            </div>
          </template>

          <b-row v-if="userDetail" class="card border border-dark p-2 mb-0 text-left" :style="$store.state.appConfig.layout.skin === 'dark' ? 'border-color: white !important' : ''">
            <b-col class="d-flex justify-content-between mb-1">
              <div class="user-item">
                <div class="user-id">
                  <router-link
                      v-if="canAccess('user_details')"
                      :to="{
                            name: 'user_details',
                            params: { userId: id },
                        }"
                  >
                    {{ id }}
                  </router-link>
                  <span v-else>{{ id }}</span>
                </div>
                <div :class="`user-status user-status-${userDetail.user.status}`"></div>
              </div>

              <div class="text-right">
                <div>Signed up: {{ userDetail.user.created_at_timeago }}</div>
                <div class="mt-25">App:
                  <b-img v-if="userDetail.user.app_id === 13" :src="primeOpinionLogo" alt="prime-opinion-logo" width="10px"></b-img>
                  <b-img v-if="userDetail.user.app_id === 16" :src="fiveSurveysLogo" alt="five-surveys-logo" width="16px"></b-img>
                  {{ userDetail.app_name }}
                </div>
              </div>
            </b-col>

            <b-col class="mb-1">
              <div class="mb-50">
                <CountryElement :name="userDetail.user.country_name" :code="userDetail.user.country_code" />
              </div>
              <div v-if="userDetail.supplier_bans.length" class="d-flex align-items-center">
                <span class="text-nowrap">Banned for:</span>
                <span class="ml-50">
                    {{ userDetail.supplier_bans.map(supplier => supplier.supplier_name).join(', ') }}
                  </span>
              </div>
            </b-col>

            <b-col>
              <b-row>
                <b-col cols="4" class="mb-50">Clicks:</b-col>
                <b-col cols="7">{{ userDetail.performance.clicks_count }}</b-col>

                <b-col cols="4" class="mb-50">Completes:</b-col>
                <b-col cols="7">
                  <b>{{ userDetail.performance.completes_count }}</b> ({{ userDetail.performance.completes_percentage | percentage }})
                </b-col>

                <b-col cols="4" class="mb-50">Reconciled:</b-col>
                <b-col cols="8">
                  <span class="text-danger">{{ userDetail.performance.reconciled_count ? parseFloat(userDetail.performance.reconciled_count).toFixed() : 0 }}</span>
                  ({{ userDetail.performance.reconciled_percentage | percentage }})
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-skeleton-wrapper>
      </div>
    </b-tooltip>

    <div
        v-show="showUserDetailModal"
        @click.self="showUserDetailModal = false"
        tabindex="1"
        ref="userDetailModal"
        class="user-detail-modal"
        @keydown.esc="showUserDetailModal = false"
    >
      <transition name="slide-right">
        <div v-show="showUserDetailModal" class="user-detail-modal-content" key="modal-content">
          <div class="user-detail-modal-content__header d-flex p-2 w-full">
            <feather-icon @click="showUserDetailModal = false" icon="XSquareIcon" size="23" class="text-danger mr-2"/>

            <b-button @click="openFullPage" size="sm" variant="outline-success">Open Full Page</b-button>
            <b-button @click="copyToClipboard(userDetailsURL)" size="sm" variant="outline-info">Copy Profile URL</b-button>
          </div>
          <UserDetails v-if="showUserDetailModal || isUserDetailsLoaded" :id="id" :status.sync="status" :shownAsModal="showUserDetailModal" style="margin-top: -5px;" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {$themeConfig} from '@themeConfig'
import UserDetails from '@/views/users/UserDetails.vue'
import store from '@/store'

export default {
  name: 'UserElement',
  components: { UserDetails },
  props: {
    id: {
      type: Number,
      required: true
    },
    status: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      showIconNewTab: false,
      loading: true,
      userDetail: null,
      primeOpinionLogo: null,
      fiveSurveysLogo: null,
      showUserDetailModal: false,
      isUserDetailsLoaded: false,
      parentQuickLinkProperties: {}
    }
  },
  computed: {
    tooltipTargetId () {
      // Generate a unique ID based on id prop and an index
      return `user-tooltip-target-${this.id}-${this._uid}`
    },
    userDetailsURL () {
      return window.location.origin + this.$router.resolve({ name: 'user_details', params: { userId: this.id } }).href
    }
  },
  watch: {
    id: {
      handler () {
        if (this.userDetail) {
          this.loading = true
          this.userDetail = null
        }
      }
    },
    showUserDetailModal (newVal) {
      this.$nextTick(() => {
        if (newVal && this.$refs.userDetailModal) {
          this.$refs.userDetailModal.focus()
          this.isUserDetailsLoaded = true
        }
      })

      this.$store.commit('app/SET_QUICK_LINK_MODAL_STATUS', newVal)

      if (!newVal) {
        this.$store.commit('app/SET_QUICK_LINK_PROPERTIES', this.parentQuickLinkProperties)
      }
    }
  },
  methods: {
    handleLinkClick (event) {
      // Only prevent the default behaviour when Cmd / Ctrl is NOT pressed
      // This will let the browser open the link in a new tab
      if (!(event.metaKey || event.ctrlKey)) {
        event.preventDefault()
        this.showUserDetailModal = true
      }
    },
    getBasicDetail () {
      if (!this.userDetail) {
        this.$http.get(
          `/api/user/${  this.id  }/basic`
        ).then((response) => {
          this.userDetail = response.data
          this.loading = false
        }).catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
      }
    },
    openUserDetails () {
      const route = this.$router.resolve({ name: 'user_details', params: { userId: this.id } })
      window.open(route.href, '_blank')
    },
    openFullPage () {
      this.$router.push(`/user/${  this.id  }`)
    }
  },
  mounted () {
    this.primeOpinionLogo = $themeConfig.app.appLogoImage
    this.fiveSurveysLogo = $themeConfig.app.fiveSurvesLogoImage
    this.parentQuickLinkProperties = store.state.app.quickProperties
  }
}
</script>

<style>
.tooltip-user-details {
  z-index: 9999 !important;
}

.modal-dialog .tooltip.b-tooltip-light .tooltip-inner,
.modal-dialog .tooltip.b-tooltip-dark .tooltip-inner {
  background: transparent !important;
}

.user-detail-modal-content {
  position: fixed;
  top: 135px;
  background: white;
  padding-top: 0;
  width: 86%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 7px;
  padding-bottom: 100px;
}

.user-detail-modal {
  z-index: 111;
  position: fixed;
  top: 0;
  //top: 135px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  outline: none;
}

.user-detail-modal-content__header {
  z-index: 1;
  gap: 5px;
  position: sticky;
  top: .1px;
  background: white;
}

.dark-layout .user-detail-modal-content__header {
  background: #283046;
}

.dark-layout .user-detail-modal-content {
  background: #283046;
}

.slide-right-enter-active {
  animation: slide-in 0.4s ease forwards;
}
.slide-right-leave-active {
  animation: slide-out 0.4s ease forwards;
}

@keyframes slide-in {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}

@keyframes slide-out {
  0% { transform: translateX(0); }
  100% { transform: translateX(100%); }
}
</style>
