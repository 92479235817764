// Date Format yyyy-mm-dd
export const getFormattedDate = (date) => {
  const d = new Date(date)

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const month = d.getMonth()
  const day = d.getDate()
  const year = d.getFullYear()

  return `${months[month]} ${day}, ${year} `
}

export function getFormatTime (date) {
  const d = new Date(date)

  let hours = d.getHours()
  let minutes = d.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? `0${minutes}` : minutes

  return `${hours}:${minutes} ${ampm}`
}

export function secondsToHMS (sec) {
  const d = Math.floor(sec / 86400)
  const h = Math.floor(sec % 86400 / 3600)
  const m = Math.floor(sec % 3600 / 60)
  const s = Math.floor(sec % 3600 % 60)

  const days = d ? `${d}d, ` : ''
  const hours = h ? `${h}:` : ''
  const minutes = m ? ((m  < 10 && h) ? `0${m}` : m) : '00'
  const seconds = s < 10 ? `0${s}` : s

  return `${days}${hours}${minutes}:${seconds}`
}

export function secondsToHMSReader (sec) {
  if (!sec) {
    return ''
  }

  const d = Math.floor(sec / 86400)
  const h = Math.floor(sec % 86400 / 3600)
  const m = Math.floor(sec % 3600 / 60)
  const s = Math.floor(sec % 3600 % 60)

  const days = d ? `${d}d` : ''
  const hours = h ? `${h}h` : (d ? '0h' : '')
  const minutes = m ? `${m}m` : (h ? '0m' : '')
  const seconds = `${s}s`

  return `${days} ${hours} ${minutes} ${seconds}`
}

export function timeAgo (dateString, dateNow = new Date(), extended = false, showTime = false) {
  if (!dateString) {
    return ''
  }

  const date = new Date(dateString)
  const seconds = Math.ceil((Number(dateNow) - Number(date)) / 1000)

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60
  }

  if ((dateNow - date) > intervals.day * 1000 && extended) {
    const date = getFormattedDate(dateString)
    const time = getFormatTime(dateString)

    return showTime ? `${date} ${time}` : date
  }

  for (const [unit, interval] of Object.entries(intervals)) {

    const count = Math.floor(seconds / interval)
    if (count >= 1) {
      return `${count} ${unit}${count === 1 ? '' : 's'} ago`
    }
  }

  return 'less a minute ago'
}

export function getFormattedDateTimeUTC (dateString) {
  if (!dateString) {
    return ''
  }

  return `${new Date(dateString).toUTCString().replace('GMT', 'UTC')}`
}

export function isImageFormat (fileFormat) {
  return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(fileFormat)
}

export function getTrimmedText (text, num) {
  if (text.length > num) {
    return `${text.substring(0, num)}...`
  }

  return text
}

export function isInteger (value) {
  return /^\d+$/.test(value)
}

export function generateId () {
  return `_${  Math.random().toString(36).substring(2, 9)}`
}

export function dataURLtoFile (dataurl, filename) {
  const  arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[arr.length - 1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type:mime })
}

export function convertBytesToMb (bytes) {
  return Number((bytes / (1024 * 1024)).toFixed(2))
}

export function formatWeekLabel (week) {
  const [start, end] = week.split(' - ')
  const startDate = new Date(start)
  const endDate = new Date(end)

  const options = { day: '2-digit', month: 'short' }
  const startFormatted = startDate.toLocaleDateString(undefined, options)
  const endFormatted = endDate.toLocaleDateString(undefined, options)

  if (startDate.getFullYear() === endDate.getFullYear()) {
    return `${startFormatted} - ${endFormatted}`
  } else {
    const yearOptions = { ...options, year: 'numeric' }
    const endFormattedWithYear = endDate.toLocaleDateString(undefined, yearOptions)
    return `${startFormatted} - ${endFormattedWithYear}`
  }
}
