<--  
In VoerrorTagsInput.vue,
if (this.typeaheadCallback) { this.typeaheadCallback(searchQuery) .then((results) => { this.typeaheadTags = results; }); }
has to be changed to this:
if (this.typeaheadCallback) { this.typeaheadCallback(searchQuery) .then((results) => { this.typeaheadTags = results; this.doSearch(searchQuery); }); } 
-->
<template>
  <div>
      <tags-input 
        ref="tagsinput"  
        :id-field="(typeof elementSettings.tag_inputs_id_field === 'string' ? elementSettings.tag_inputs_id_field: 'id')" 
        :text-field="(typeof elementSettings.tag_inputs_text_field === 'string' ? elementSettings.tag_inputs_text_field: 'name')" 
        :only-existing-tags="true" 
        :class="errorClasses" 
        v-model="elementSettings.value"
        :existing-tags="(elementSettings.data instanceof Array ? elementSettings.data : [])" 
        :typeahead-callback="(typeof elementSettings.data === 'string' ? search_tags: null)" 
        :typeahead="true"></tags-input>
        <div class="text-error-notification" v-if="isError && typeof elementSettings.errorMessage === 'string' && elementSettings.isValidated">{{elementSettings.errorMessage}}</div>
  </div>
</template>
<script>
import TagsInput from '@voerro/vue-tagsinput'
export default {
  components: {
    TagsInput
  },
  props: {
    elementSettings: {
      required: true,
      type: Object
    },
    formSettings: {
      required: true,
      type: Object
    }
  },
  methods: {
    search_tags (query) {
      return new Promise((resolve) => {
        this.$http
          .get(this.elementSettings.data, {
            params: {
              search: query
            }
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((errors) => {
            this.alertError(errors.response.data)
            resolve([])
          })
      })
    },
    executeElementValidate () {
      this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
    }
  },
  computed: {
    isError: {
      cache: false,
      get () {
        this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
        return this.elementSettings.isError
      }
    },
    errorClasses: {
      cache: false,
      get () {
        if (typeof this.elementSettings.errorClasses === 'string') {
          if (this.elementSettings.isValidated && this.isError) {
            return this.elementSettings.errorClasses
          }
          return ''
        } else {
          return ''
        }
      }
    }
  }
}
</script>
<style>
/* The input */
.tags-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tags-input input {
    flex: 1;
    background: transparent;
    border: none;
}

.tags-input input:focus {
    outline: none;
}

.tags-input input[type="text"] {
    color: #495057;
}

.tags-input-wrapper-default {
    padding: .5em .25em;
    background: #fff;
    border: 1px solid transparent;
    border-color: #dbdbdb;
}

.tags-input-wrapper-default.active {
    border: 1px solid #8bbafe;
    box-shadow: 0 0 0 0.2em rgba(13, 110, 253, 0.25);
    outline: 0 none;
}

/* The tag badges & the remove icon */
.tags-input span {
    margin-right: 0.3em;
}

.tags-input-remove {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    right: 0.3em;
    top: 0.3em;
    padding: 0.5em;
    overflow: hidden;
}

.tags-input-remove:focus {
    outline: none;
}

.tags-input-remove:before, .tags-input-remove:after {
    content: '';
    position: absolute;
    width: 75%;
    left: 0.15em;
    background: red;
    
    height: 2px;
    margin-top: -1px;
}

.tags-input-remove:before {
    transform: rotate(45deg);
}
.tags-input-remove:after {
    transform: rotate(-45deg);
}

/* Tag badge styles */
.tags-input-badge {
    position: relative;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 90%;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tags-input-badge-pill {
    padding-right: 1.25em;
    padding-left: 0.6em;
    border-radius: 10em;
    margin-top: 0.15rem;
    margin-bottom: 0.15rem;
}
.tags-input-badge-pill.disabled {
    padding-right: 0.6em;
}

.tags-input-badge-selected-default {
    color: #212529;
    background-color: #f0f1f2;
}

/* Typeahead */
.typeahead-hide-btn {
    color: #999 !important;
    font-style: italic;
}

/* Typeahead - badges */
.typeahead-badges {
    line-height: 0px;
}
.typeahead-badges > span {
    margin-top: .5em;
}

.typeahead-badges > span {
    cursor: pointer;
    margin-right: 0.3em;
}

/* Typeahead - dropdown */
.typeahead-dropdown {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.typeahead-dropdown li {
    padding: .25em 1em;
    cursor: pointer;
}

/* Typeahead elements style/theme */
.tags-input-typeahead-item-default {
    color: #fff;
    background-color: #343a40;
}

.tags-input-typeahead-item-highlighted-default {
    color: #fff;
    background-color: #007bff !important;
}
.tags-input-root > div:nth-child(2) {
    position: absolute;
    z-index: 9;
}
.tags-input-root > .tags-input-wrapper-default {
    min-height: 39px;
    border-radius: 0.357rem;
}
</style>