<template>
  <div>
    <div v-show="this.data.total > 0" style="display: inline-block; float: left; line-height: 2rem;">
      From {{ data.from }} to {{ data.to }}
      <span v-if="!data.is_simple_paginate"> of {{ data.total }}</span>
    </div>
    <div v-show="this.data.total > 0 || data.is_simple_paginate" class="con-vs-pagination vs-pagination-primary" style="display: inline-block; float: right;">
        <ul class="pagination b-pagination" style="margin-bottom: 0px;">
            <li :class="{'disabled': !hasFirstPage}" @click="goToFirstPage()" class="page-item">
                <span class="page-link"> &#171; </span>
            </li>
            <li :class="{'disabled': !hasPreviousPage}" @click="goToPreviousPage()" class="page-item">
                <span class="page-link"> &#8249; </span>
            </li>
            <li v-if="data.is_simple_paginate" class="page-item active">
              <span class="page-link">{{ page }}</span>
            </li>
            <li v-else v-for="(onePage, index) in listPages" :key="index" @click="goToPage(onePage)" class="page-item" :class="{'active': onePage === page}">
                <span class="page-link"> {{onePage}} </span>
            </li>
            <li :class="{'disabled': !hasNextPage}" @click="goToNextPage()" class="page-item">
                <span class="page-link"> &#8250; </span>
            </li>
            <li :class="{'disabled': !hasLastPage}" @click="goToLastPage()"  class="page-item">
                <span class="page-link"> &raquo; </span>
            </li>
        </ul>
    </div>
    <div style="clear:both"></div>
  </div>
</template>

<script>
export default {
  name: 'PaginationAndTableInfo',
  props: ['data', 'useQueryUrl'],
  data () {
    return {
      page: 1
    }
  },
  methods: {
    goToPage (pageNumber) {
      this.page = pageNumber

      if (this.useQueryUrl) this.setParamsPaginationToUrl()

      this.$emit('change', this.page)
    },
    setParamsPaginationToUrl () {
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.set('page', this.page)
      const newUrl = `${window.location.pathname  }?${  queryParams.toString()}`
      // const humanizeUrl = decodeURIComponent(newUrl);
      window.history.replaceState({}, '', newUrl)
    },
    goToFirstPage () {
      if (this.hasFirstPage) {
        this.goToPage(1)
      }
    },
    goToPreviousPage () {
      if (this.hasPreviousPage) {
        this.goToPage(this.page - 1)
      }
    },
    goToNextPage () {
      if (this.hasNextPage) {
        this.goToPage(this.page + 1)
      }
    },
    goToLastPage () {
      if (this.hasLastPage) {
        this.goToPage(this.data.total_page)
      }
    }
  },
  computed: {
    hasFirstPage () {
      return this.data.total_page > 0 &&
        this.page > 1
    },
    hasPreviousPage () {
      return this.data.total_page > 0 &&
        this.page > 1
    },
    hasNextPage () {
      return (this.data.total_page > 0 && this.page < this.data.total_page) || this.data.is_simple_paginate
    },
    hasLastPage () {
      return this.data.total_page > 0 &&
        this.page < this.data.total_page
    },
    listPages () {
      const numberOfPage  = this.data.number_of_page > 0 ? this.data.number_of_page : 5
      if (
        this.data.total_page === 0 ||
        this.data.total === 0
      ) return []
      if (this.data.total_page <= 5) {
        return Array.from({length: Math.min(5, this.data.total_page)}, (x, i) => i + 1)
      }
      if (this.page <= 3) {
        return Array.from({length: Math.min(5, this.data.total_page)}, (x, i) => i + 1)
      }
      if (this.page >= this.data.total_page - 2) {
        return [this.data.total_page - 4, this.data.total_page - 3, this.data.total_page - 2, this.data.total_page - 1, this.data.total_page]
      }
      return [this.page - 2, this.page - 1, this.page, this.page + 1, this.page + 2]
    }
  },
  watch: {
    data (newVal, oldVal) {
      this.page = newVal.page
    }
  },
  mounted () {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page)
    }
  }
}
</script>

<style>
.page-item {
  cursor: pointer;
}
</style>
