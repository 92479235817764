<template>
  <table class="table b-table table-striped table-hover" v-if="similarUserProfilings">
    <thead>
    <tr>
      <th>Similarity</th>
      <th>User</th>
      <th>App</th>
      <th>Type</th>
      <th>Date</th>
      <th>Open end</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="similarUserProfiling in similarUserProfilings" :key="similarUserProfiling.user_id">
      <td>{{ similarUserProfiling.similarity_percentage.toFixed() }} %</td>
      <td>
        <router-link v-if="canAccess('user_details')"
          :to="{
            name: 'user_details',
            params: {
                userId: similarUserProfiling.user_id,
            },
          }"
        >
          <UserElement :id="similarUserProfiling.user_id" :status="similarUserProfiling.status"/>
        </router-link>
        <span v-else>{{ similarUserProfiling.user_id }}</span>
      </td>
      <td>
        <router-link v-if="canAccess('app_details')" :to="{ name: 'app_details', params: { appHash: similarUserProfiling.app_hash }}">
          {{ similarUserProfiling.app_name }}
        </router-link>
        <span v-else>{{ similarUserProfiling.app_name }}</span>
      </td>
      <td>
        <span v-if="similarUserProfiling.similarity_percentage === 100">Identical</span>
        <span v-else>Similar</span>
      </td>
      <td>{{ similarUserProfiling.created_at | dotDateShort }}</td>
      <td>{{ similarUserProfiling.answer_input }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    similarUserProfilings: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style>

</style>
