<template>
  <div>
        <b-form-checkbox-group
            v-model="elementSettings.value" 
            :class="errorClasses" 
        >
            <b-form-checkbox v-for="(item, idx) in elementSettings.data" :key="idx" :value="item.value">{{item.text}}</b-form-checkbox>
        </b-form-checkbox-group>
        <div class="text-error-notification" v-if="isError && typeof elementSettings.errorMessage === 'string' && elementSettings.isValidated">{{elementSettings.errorMessage}}</div>
  </div>
</template>
<script>
export default {
  props: {
    elementSettings: {
      required: true,
      type: Object
    },
    formSettings: {
      required: true,
      type: Object
    }
  },
  methods: {
    executeElementValidate () {
      this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
    }
  },
  computed: {
    isError: {
      cache: false,
      get () {
        this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
        return this.elementSettings.isError
      }
    },
    errorClasses: {
      cache: false,
      get () {
        if (typeof this.elementSettings.errorClasses === 'string') {
          if (this.elementSettings.isValidated && this.isError) {
            return this.elementSettings.errorClasses
          }
          return ''
        } else {
          return ''
        }
      }
    }
  },
  mounted () {
    // this.elementSettings.validate = () => {
    //     this.elementSettings.isError = this.isErrors;
    // };
  }
}
</script>