<template>
    <b-modal
          :id="modalUuid"
          :ref="modalUuid"
          title="Reset Data"
          :hide-footer="true"
          size="lg"
      >
          <table class="table b-table table-striped table-hover" v-if="supplier">
              <thead>
                  <tr>
                      <th>Hashes</th>
                      <th>External ID</th>
                      <th>Status</th>
                      <th>Created At</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(item, key) in supplier.all_hashes" :key="key">
                      <td>{{ item.hash }}</td>
                      <td>{{ item.external_id }}</td>
                      <td>
                        <b-badge v-if="item.status == 1" variant="success" size="sm">Active</b-badge>
                        <b-badge v-else-if="item.status == 0" variant="secondary" size="sm">Inactive</b-badge>
                      </td>
                      <td>{{ item.created_at | dotDateTime }}</td>
                  </tr>
              </tbody>
          </table>
      </b-modal>
  </template>

<script>
export default {
  props: {
    id: {
      required: true
    }
  },
  data () {
    return {
      modalUuid: `supplier-reset-hashes-modal${ this.id }`,
      supplier: null
    }
  },
  methods: {
    showModal (data) {
      this.supplier = data
      this.$bvModal.show(this.modalUuid)
    }
  }
}
</script>

  <style>

  </style>
