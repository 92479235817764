<template>
  <div>
    <b-badge v-if="status_id == 1" variant="success" style="font-size: 11px;">Active</b-badge>
    <b-badge v-else-if="status_id == 2" variant="danger" style="font-size: 11px;">Banned</b-badge>
    <b-badge v-else-if="status_id == 3" class="user-status-3" style="font-size: 11px;">Shadow Banned</b-badge>
    <b-badge v-else-if="status_id == 4" variant="warning" style="font-size: 11px;">Review</b-badge>
    <b-badge v-else-if="status_id == 5" class="user-status-5" style="font-size: 11px;">Shadow Review</b-badge>
    <b-badge v-else-if="status_id == 6" class="user-status-6" style="font-size: 11px;">On Hold</b-badge>
    <b-badge v-else variant="dark" style="font-size: 11px;">Unknown</b-badge>
  </div>
</template>

<script>
export default {
  props: {
    status_id: {
      type: [Number, String],
      required: true
    }
  }
}
</script>
