<template>
  <div class="ps-tooltip-parent" @mouseleave="showIconNewTab = false">
    <div style="min-width: 100px;" :id="tooltipTargetId" @mouseover="canAccess('fs_user_details') && !userDetail ? getBasicDetail() : ''" class="user-item d-flex align-items-center">
      <div class="user-id">
        <router-link
            v-if="canAccess('fs_user_details')"
            :to="{
                name: 'fs_user_details',
                params: { userId: id },
            }"
        >
          {{ id }}
        </router-link>
        <span v-else>{{ id }}</span>
        <span v-if="additionalText">{{ additionalText }}</span>
      </div>
    </div>

    <b-tooltip v-if="canAccess('fs_user_details')" :target="tooltipTargetId" :variant="$store.state.appConfig.layout.skin" triggers="hover" placement="bottomright" custom-class="tooltip-user-details">
      <div class="width-350 font-small-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <div class="card p-2 mb-0">
              <div class="d-flex justify-content-between">
                <b-skeleton width="30%"></b-skeleton>
                <b-skeleton width="40%"></b-skeleton>
              </div>
              <b-skeleton width="70%"></b-skeleton>

              <b-skeleton class="mt-2" width="50%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
            </div>
          </template>

          <b-row v-if="userDetail" class="card border border-dark p-2 mb-0 text-left" :style="$store.state.appConfig.layout.skin === 'dark' ? 'border-color: white !important' : ''">
            <b-col class="d-flex justify-content-between mb-1">
              <div class="user-item">
                <div class="user-id">
                  <router-link
                      v-if="canAccess('fs_user_details')"
                      :to="{
                            name: 'fs_user_details',
                            params: { userId: id },
                        }"
                  >
                    {{ id }}
                  </router-link>
                  <span v-else>{{ id }}</span>
                  <span v-if="additionalText">{{ additionalText }}</span>
                </div>
              </div>

              <div class="text-right">
                <div>Signed up: {{ userDetail.user.created_at_timeago }}</div>
              </div>
            </b-col>

            <b-col class="mb-1">
              <div class="mb-50">
                <CountryElement :name="userDetail.user.country_name" :code="userDetail.user.country_code" />
              </div>
            </b-col>

            <b-col>
              <b-row>
                <b-col cols="4" class="mb-50">Balance:</b-col>
                <b-col cols="7"><b>{{ userDetail.balance | formatCount }} {{ userDetail.user.default_currency }}</b></b-col>

                <b-col cols="4" class="mb-50">Rewards:</b-col>
                <b-col cols="7">
                  <div v-if="userDetail.total_reward">
                    <b>{{ userDetail.total_reward }}</b> ({{ userDetail.top_rewards.join(', ') }})
                  </div>
                  <span v-else>-</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-skeleton-wrapper>
      </div>
    </b-tooltip>
  </div>
</template>

<script>

export default {
  name: 'FSUserElement',
  props: {
    id: {
      type: Number,
      required: true
    },
    additionalText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showIconNewTab: false,
      loading: true,
      userDetail: null,
      primeOpinionLogo: null,
      fiveSurveysLogo: null,
      showUserDetailModal: false,
      isUserDetailsLoaded: false
    }
  },
  computed: {
    tooltipTargetId () {
      // Generate a unique ID based on id prop and an index
      return `user-tooltip-target-${this.id}-${this._uid}`
    }
  },
  methods: {
    getBasicDetail () {
      if (!this.userDetail) {
        this.$http.get(
          `/api/five-surveys/user/${  this.id  }/basic`
        ).then((response) => {
          this.userDetail = response.data
          this.loading = false
        }).catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
      }
    }
  }
}

</script>
