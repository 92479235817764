import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from '@ps_main/store_auth'
import autoRefresh from '@/store/modules/auto-refresh'
import chat from "@/store/modules/chat"

Vue.use(Vuex)


const listExcludeModules = ['ModuleBase']
const requireModules = require.context('@/store/modules', true, /\.js$/)
const listModules = {}
for (const file of requireModules.keys()) {
  const moduleConfig = requireModules(file)
  const moduleName = file
    .replace(/.js/, '')
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
  if (listExcludeModules.indexOf(moduleName) < 0) listModules[moduleName] = moduleConfig.default || moduleConfig
}

const appStore = new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    autoRefresh,
    chat,
    ...listModules
  },
  strict: process.env.DEV
})

export default appStore
