import Vue from 'vue'
// import axios from 'axios'

Vue.directive('log-click', {
  bind (el, binding) {
    el.addEventListener('click', function () {
      const fullUrl = window.location.href

      window.PRIME_VUE_OBJECT.$http.post('/api/admin-logs', {
        type: 'action',
        action: binding.value || 'button_click',
        request_url: fullUrl
      })
    })
  }
})
