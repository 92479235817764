import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const PrimeSurveys = {
  // eslint-disable-next-line no-unused-vars
  install (Vue, options) {
    Vue.prototype.alertError = function (info) {
      let message = this.extractMessage(info)
      if (message === '') {
        message = 'Unknown message'
      }
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: message
        }
      })
    }
    Vue.prototype.alertWarning = function (info) {
      let message = this.extractMessage(info)
      if (message === '') {
        console.log(info)
        message = 'Unknown message'
      }
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Warning',
          icon: 'AlertCircleIcon',
          variant: 'warning',
          text: message
        }
      })
    }
    Vue.prototype.alertSuccess = function (info) {
      let message = this.extractMessage(info)
      if (message === '') {
        message = 'Unknown message'
      }
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
          text: message
        }
      })
    }
    Vue.prototype.extractMessage = function (data) {
      if (typeof data === 'object' && typeof data.message === 'string' && data.message.startsWith('{')) {
        data = JSON.parse(data.message)
      }
      if (typeof data === 'string') {
        return data
      }
      if (typeof data === 'object' && typeof data.message === 'string') {
        return data.message
      }
      if (
        typeof data === 'object' &&
        typeof data.error === 'string'
      ) {
        return data.error
      }

      if (data.errors instanceof Array) {
        return data.errors.join('<br/>')
      }

      if (
        typeof data.errors === 'object' &&
        data.errors !== null
      ) {
        data = data.errors
      }

      const messages = []
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'string') {
          messages.push(value)
        } else {
          for (const item in value) {
            if (typeof value[item] === 'string') {
              messages.push(value[item])
            }
          }
        }
      }
      return messages.join('<br/>')
    }

    Vue.prototype.copyText = function (text) {
      const thisIns = this
      this.$copyText(text).then(function () {
        thisIns.alertSuccess('Copied successfully!')
      }, function () {
        thisIns.alertError('Copied fails!')
      })
    }

    Vue.prototype.laravelPaginationPages = function (laravelPaginationData) {
      if (
        typeof laravelPaginationData === 'object' &&
        laravelPaginationData !== null &&
        laravelPaginationData.last_page >= 0
      ) {

        return {
          total_page: laravelPaginationData.last_page,
          page: laravelPaginationData.current_page,
          from: laravelPaginationData.from,
          to: laravelPaginationData.to,
          total: laravelPaginationData.last_page === 1 ? laravelPaginationData.to : laravelPaginationData.total,
          is_simple_paginate: !laravelPaginationData.total
        }
      }

      return {
        total_page: 0,
        page: 1,
        from: 0,
        to: 0,
        total: 0
      }
    }

    // eslint-disable-next-line no-unused-expressions
    Vue.prototype.handleResponseError = function (response) {
      if (
        response instanceof Error
      ) {
        this.alertError(response.response.statusText)
        return true
      }

      return false
    },

    Vue.prototype.generateSyncObject = function (orgObject, modifiedData) {
      const object = orgObject
      for (const key in modifiedData) {
        object[key] = modifiedData[key]
      }
      return object
    }

    Vue.prototype.uuidv4 = function () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line no-mixed-operators
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    }

    Vue.prototype.validate = function (elementSettings, formSettings) {
      let result = true
      if (typeof elementSettings.validate === 'function') {
        result = elementSettings.validate(elementSettings, formSettings)
        return result
      }
      let rules = []
      if (elementSettings.rules instanceof Array) {
        rules = elementSettings.rules
      } else if (typeof elementSettings.rules === 'string') {
        rules = elementSettings.rules.split(',')
      } else {
        return true
      }
      if (rules.length === 0) {
        return true
      }
      for (let i = 0; i < rules.length; i++) {
        const oneRule = rules[i]
        const value = elementSettings.value
        const elementType = elementSettings.type
        switch (oneRule) {
        case 'required':
          // eslint-disable-next-line eqeqeq
          if (elementType === 'select' && (value == 0 && value == '0' && value.trim() == '')) {
            return false
          }
          if (typeof value === 'string' && value.trim() === '') {
            return false
          }
          if (typeof value === 'object' && value === null) {
            return false
          }
          if (value instanceof Array && value.length === 0) return false
          break
        case 'url':
          // eslint-disable-next-line no-case-declarations
          const expression = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?/gi
          // eslint-disable-next-line no-case-declarations
          const regex = new RegExp(expression)
          if (!value.match(regex)) {
            return false
          }
          break
        case 'int':
          // eslint-disable-next-line no-case-declarations,eqeqeq
          const result = !isNaN(parseInt(value, 10)) && (parseFloat(value, 10) == parseInt(value, 10))
          if (!result) return false
          break
        case 'float':
          // eslint-disable-next-line no-case-declarations
          const val = parseFloat(value)
          if (isNaN(val)) {
            return false
          }
          break
        case 'email':
          // eslint-disable-next-line no-case-declarations
          const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (!emailRegex.test(value.toLowerCase())) return false
          break
        default:
          throw Error(`This rule ${  oneRule  } is not installed yet`)
        }
      }
      return true
    }

    Vue.prototype.refreshMenu = function () {
      this.$store.commit('auth/REFRESH_MENU')
    }

    Vue.prototype.showLoading = function () {
      const spinner = document.getElementById('spinner')
      if (!spinner) return
      spinner.style.display = 'block'
    }

    Vue.prototype.hideLoading = function () {
      const spinner = document.getElementById('spinner')
      if (!spinner) return
      spinner.style.display = 'none'
    }

    Vue.prototype.getQuestionTypeText = function (type) {
      const intType = parseInt(type)
      switch (intType) {
      case 1: return 'Single choice'
      case 2: return 'Multiple choices'
      case 3: return 'Integer number'
      case 4: return 'Text'
      case 5: return 'Long text'
      case 6: return 'Others'
      case 7: return 'Range integer number'
      default: return 'Vue.prototype.getQuestionTypeText'
      }
    }

    Vue.prototype.getQualifySettingTypeText = function (type) {
      switch (type) {
      case 'include': return 'Select or input one of these answers'
      case 'include_manual': return 'Select or input one of these answers'
      case 'equal': return 'Select or input exact these answer(s)'
      case 'exact': return 'Select or input exact these answer(s)'
      case 'exclude': return 'Unallowable to select or input these answers'
      case 'range': return 'Input in range'
      case 'any': return 'Select or input any answers'
      default: return `${type  } Vue.prototype.getQualifySettingTypeText`
      }
    }

    Vue.prototype.groupBy = function (xs, f) {
      return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {})
    }

    Vue.prototype.compare2DateWithTimeZone = function (firstDate, secondDate, timeZone) {
      if (timeZone === 'UTC') {
        return firstDate.getUTCFullYear() === secondDate.getUTCFullYear() &&
            firstDate.getUTCMonth() === secondDate.getUTCMonth() &&
            firstDate.getUTCDate() === secondDate.getUTCDate()
      } else {
        return firstDate.toDateString() === secondDate.toDateString()
      }
    }

    Vue.prototype.assignObjNotAppend = function (a, b) {
      const aKeys = Object.keys(a)
      const bKeys = Object.keys(b)
      for (const aKey in aKeys) {
        if (bKeys.indexOf(aKey) >= 0) {
          // eslint-disable-next-line no-undef
          a[aKey] = structuredClone(b[aKey])
        }
      }
      return a
    }

    Vue.prototype.cloneObjWithKeys = function (a, keys) {
      const obj = {}
      const aKeys = Object.keys(a)
      for (const key in keys) {
        if (aKeys.indexOf(key) >= 0) {
          // eslint-disable-next-line no-undef
          obj[key] = structuredClone(a[key])
        }
      }
      return obj
    }

    Vue.prototype.canAccess = function (pageName) {
      return this.$router.canAccessPage(pageName)
    }

    Vue.prototype.generateRandomKey = function () {
      return Math.random().toString(16).slice(2)
    }

    Vue.prototype.ymdDateStrToTZDateStr = function (ymdDate) {
      return `${ymdDate  }T23:59:59.000000Z`
    }

    Vue.prototype.ymdHisDateStrToTZDateStr = function (ymdHisDate) {
      const dateAndTime = ymdHisDate.split(' ')
      return `${dateAndTime[0]  }T${  dateAndTime[1]  }.000000Z`
    }

    Vue.prototype.isTestMode = function () {
      return window.location.href.indexOf('.local') >= 0
    }

    Vue.prototype.copyToClipboard = async function (text) {
      this.sendActionLog(`copy to clipboard from ${this.$route.path} with text: ${text}`)

      try {
        //Showing the success first to make the text visible, even if the copy operation is not succesful.
        //If not succesful [doens't work on HTTP] user will be alerted that it was not actually copied.
        this.alertSuccess(`'${text}' copied to clipboard`)
        await window.navigator.clipboard.writeText(text)
      } catch (e) {
        this.alertError('Something went wrong. Note this function only works on HTTPS connectinos')
      }
    }

  }
}

export {PrimeSurveys}
