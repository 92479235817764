import itemSchema from '@/store/modules/schemas/user.schema'
import ModuleBase from '@/store/modules/ModuleBase'
import axios from '@/main/axios'
import {isInteger} from 'lodash'

const resourceName = 'message-templates'
const base = ModuleBase(itemSchema, resourceName)
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
    messageTemplates: null
  },
  getters: {
    ...base.getters
  },
  mutations: {
    ...base.mutations
  },
  actions: {
    ...base.actions
  }
}
