import itemSchema from '@/store/modules/schemas/default.schema'
import ModuleBase from '@/store/modules/ModuleBase'

const resourceName = 'configs'
const base = ModuleBase(itemSchema, resourceName)
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state
  },
  getters: {
    ...base.getters
  },
  mutations: {
    ...base.mutations
  },
  actions: {
    ...base.actions
  }
}