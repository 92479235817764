<template>
    <b-modal
        :id="modalUUid"
        :ref="modalUUid"
        title="Trigger Review Status?"
        :hide-footer="true"
    >
        <BaseForm
            ref="main_form"
            :settings="reviewFormSettings"
            @success="success"
            @cancel="$bvModal.hide(modalUUid)"
            v-slot="{ save, cancel }"
        >
            <b-row class="mb-1">
                <b-col cols="3"> Internal Comment </b-col>
                <b-col cols="9">
                    <FormElement
                        ref="comment"
                        :elementSettings="reviewFormSettings.formElements.comment"
                        :formSettings="reviewFormSettings"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button variant="primary" @click="save()" class="mr-1">
                        Yes
                    </b-button>
                    <b-button variant="warning" @click="cancel()"
                        >No</b-button
                    >
                </b-col>
            </b-row>
        </BaseForm>
    </b-modal>
</template>

<script>
export default {
  props: {
    userId: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      modalUUid: `review-status-confirm-modal${ this.userId }`,
      refreshKey: 'review-status-confirm-modal',
      reviewFormSettings: {
        addUrl: `/api/user/${  this.userId  }/review-status`,
        primaryKey: 'id',
        formElements: {
          comment: {
            type: 'textarea',
            defaultValue: '',
            rules: ['required'],
            post_param: 'comment',
            value: '',
            errorClass: 'input-error',
            errorMessage: 'Internal comment is required!',
            isValidated: false,
            place_holder: 'Input internal comment',
            isError: false
          }
        },
        vueContainer: this
      }
    }
  },
  methods: {
    showForm () {
      this.$bvModal.show(this.modalUUid)

      this.$nextTick(() => {
        this.getFormVue().clearForm()
      })
    },
    success (data) {
      this.$emit('changed', data)
      this.$bvModal.hide(this.modalUUid)
    },
    getFormVue () {
      return this.$refs.main_form
    }
  }
}
</script>

<style>
</style>
