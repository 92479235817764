import inspector from 'schema-inspector'
import axios from '@ps_main/axios.js'
import cloneDeep from 'clone-deep'

const basePath = `/api/`

const buildQuerySearch = (params) => {
  const options = params.options
  const query = {
    limit: options.itemsPerPage,
    page: options.page,
    silent: params.querySilent ? params.querySilent : false
  }
  if (options.sortBy !== null && options.sortDesc !== null) {
    query.orderBy = options.sortBy
    query.sortedBy = options.sortDesc === true ? 'desc' : 'asc'
  }

  if (params.searchWith) query.with = params.searchWith
  if (params.searchWithCount) query.withCount = params.searchWithCount

  const search = params.searchFields ? params.searchFields : {}
  const searchValues = []
  const searchTypes = []
  Object.keys(search).forEach(key => {
    if (search[key].value !== '' && search[key].type) {
      searchValues.push(`${key  }:${  search[key].value}`)
      searchTypes.push(`${key  }:${  search[key].type}`)
    }
  })
  if (searchValues.length && searchTypes.length) {
    query.search = searchValues.join(';')
    query.searchFields = searchTypes.join(';')
    query.searchJoin = options.searchJoin ? options.searchJoin : 'AND'
  }

  if (params.others) {
    Object.keys(params.others).forEach(key => {
      if (typeof query[key] === 'undefined') {
        query[key] = params.others[key]
      }
    })
  }

  return query
}

export default (itemSchema, resourceName) => {
  return {
    namespaced: true,
    state: {
      all: [],
      searchParams: {},
      selectedId: null,
      pagination: {
        current_page: 0,
        per_page: 0,
        last_page: 0,
        from: 0,
        to: 0,
        total: 0,
        first_page_url: '',
        last_page_url: '',
        next_page_url: '',
        prev_page_url: '',
        path: ''
      }
    },

    getters: {
      all: state => state.all,
      getById: state => id => {
        return state.all.filter(i => i.id === id)
      },
      getSingleById: state => id => {
        id = parseInt(id)
        const filters = state.all.filter(i => i.id === id)
        if (filters.length) return filters[0]
        return null
      },
      getByFields: state => fields => {
        return state.all.filter(i => {
          let matched = true
          Object.keys(fields).forEach(fieldName => {
            if (i[fieldName] !== fields[fieldName]) {
              matched = false
              return false //to break forEach
            }
          })
          return matched
        })
      },
      selected: state => {
        const item = state.all.filter(i => i.id === state.selectedId)
        if (item.length) return item[0]
        return null
      },
      pagination: state => state.pagination
    },

    mutations: {
      INIT_STATE (state, items) {
        state = items
      },
      ALL (state, items) {
        state.all = items
      },
      PAGINATION (state, pagination) {
        state.pagination = pagination
      },
      ADD (state, item) {
        item = inspector.sanitize(itemSchema.sanitize, item).data
        const existedItem = state.all.filter(i => i.id === item.id)
        if (!existedItem.length) {
          const all = cloneDeep(state.all)
          all.push(item)
          state.all = all
        } else console.log('add_duplicate_item', item)
      },
      UPDATE (state, item) {
        item = inspector.sanitize(itemSchema.sanitize, item).data
        let existedItem = state.all.filter(i => i.id === item.id)
        if (existedItem.length) {
          const itemIndex = state.all.indexOf(existedItem[0])
          const all = cloneDeep(state.all)
          existedItem = existedItem[0]
          all[itemIndex] = {...existedItem, ...item}
          state.all = all
        } else console.log('item_not_found', item)
      },
      UPSERT (state, item) {
        const existedItem = state.all.filter(i => i.id === item.id)
        const all = cloneDeep(state.all)
        if (existedItem.length) {
          const itemIndex = state.all.indexOf(existedItem[0])
          all[itemIndex] = {...existedItem[0], ...item}
        } else all.push(item)
        state.all = all
      },
      DELETE (state, id) {
        const existedItem = state.all.filter(i => i.id === id)
        if (existedItem.length) {
          state.all = state.all.filter(i => i.id !== id)
        } else console.log(`Item with id ${  id  } not found to delete`)
      },
      SELECT (state, id) {
        state.selectedId = id
      },
      CLEAR (state) {
        state.all = []
        state.selectedId = null
        state.searchParams = {}
      }
    },

    actions: {
      get: async ({commit}, searchParams) => {
        const params = buildQuerySearch(searchParams)
        const res = await axios.get(basePath + resourceName, {params})
        let listItems = res.status === 200 && res.data && res.data.data ? res.data.data : []
        if (parseInt(params.limit) === -1) listItems = res.status === 200 && res.data ? res.data : []
        await commit('ALL', listItems)
        let pagination = {
          current_page: 1,
          per_page: listItems.length,
          last_page: 1,
          from: 0,
          to: listItems.length - 1,
          total: listItems.length,
          first_page_url: '',
          last_page_url: '',
          next_page_url: '',
          prev_page_url: '',
          path: ''
        }
        if (params.limit !== -1) {
          pagination = cloneDeep(res.data)
          delete pagination.data
        }
        await commit('PAGINATION', pagination)
        return res
      },
      all: async ({commit}) => {
        const res = await axios.get(basePath + resourceName, {params: {limit: -1}})
        const listItems = res.status === 200 && res.data ? res.data : []
        await commit('ALL', listItems)
        await commit('PAGINATION', {
          current_page: 1,
          per_page: listItems.length,
          last_page: 1,
          from: 0,
          to: listItems.length - 1,
          total: listItems.length,
          first_page_url: '',
          last_page_url: '',
          next_page_url: '',
          prev_page_url: '',
          path: ''
        })
        return res
      },
      getById: async ({commit}, requestData) => {
        const res = await axios.get(`${basePath + resourceName  }/${  requestData.id}`, {params: requestData.params})
        const item = res.status === 200 && res.data ? res.data : null
        if (!item) return null
        //find item in list state and update it
        await commit('UPSERT', item)
        return item
      },
      create: async ({commit}, item) => {
        const res = await axios.post(basePath + resourceName, item)
        if (res.status === 200 && res.data && res.data.data) await commit('ADD', res.data.data)
        return res
      },
      update: async ({commit}, item) => {
        const res = await axios.put(`${basePath + resourceName  }/${  item.id}`, item)
        if (res.status === 200) await commit('UPDATE', item)
        return res
      },
      delete: async ({commit}, itemId) => {
        if (Array.isArray(itemId)) itemId = itemId.join(',')
        const res = await axios.delete(`${basePath + resourceName  }/${  itemId}`)
        await commit('DELETE', itemId)
        return res
      },
      setSelected: async ({commit}, id) => {
        return await commit('SELECT', id)
      },
      clear: async ({commit}) => {
        return await commit('CLEAR')
      },
      validateInsert: async ({state}, obj) => {
        return await state ? inspector.validate(itemSchema.validate.update, obj) : {}
      },
      validateUpdate: async ({state}, obj) => {
        return await state ? inspector.validate(itemSchema.validate.insert, obj) : {}
      },
      // eslint-disable-next-line
      import: async ({}, data) => {
        return await axios.post(`${basePath + resourceName  }/import`, data)
      },
      // eslint-disable-next-line
      export: async ({}, searchParams) => {
        const params = buildQuerySearch(searchParams)
        return await axios.get(`${basePath + resourceName  }/export`, {params, responseType: 'blob'})
      }
    },

    buildQuerySearch,

    basePath
  }
}
