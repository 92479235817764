<template>
    <b-modal
        :id="modalUUid"
        :ref="modalUUid"
        title="Activate User"
        :hide-footer="true"
    >
        <BaseForm
            ref="main_form"
            :settings="activateFormSettings"
            @success="successAddedUserBan"
            @cancel="$bvModal.hide(modalUUid)"
            v-slot="{ save, cancel }"
        >
            <b-alert :show="isNeedApproval" variant="warning" class="p-1">
              <b-icon icon="exclamation-circle" variant="warning" class="mr-50"></b-icon>
              <b class="text-warning">You need an approval for this action</b>
            </b-alert>

            <b-row class="mb-1" style="display: flex; align-items: center">
                <b-col cols="12 mb-1"> Protect from automatic Status changes for: </b-col>
                <b-col
                    cols="12"
                >
                    <div style="display: flex;gap: 20px;">
                        <div style="width: 60px; padding-top: 2px;">For</div>
                        <div>
                           <FormElement
                                ref="protected_completes"
                                style="margin-bottom: 0px;"
                                :elementSettings="activateFormSettings.formElements.protected_completes"
                                :formSettings="activateFormSettings"
                            />
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col cols="2"> Internal Comment </b-col>
                <b-col cols="10">
                    <FormElement
                        ref="comment"
                        :elementSettings="activateFormSettings.formElements.comment"
                        :formSettings="activateFormSettings"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button variant="primary" @click="save" class="mr-1">
                        Save
                    </b-button>
                    <b-button variant="warning" @click="cancel()"
                        >Cancel</b-button
                    >
                </b-col>
            </b-row>
        </BaseForm>
    </b-modal>
</template>

<script>
export default {
  props:{
    userId: {
      required: true
    },
    isNeedApproval: {
      type: Boolean,
      default: false
    },
    status: {
      required: true
    }
  },
  data () {
    const isTestMode = this.isTestMode()
    return {
      modalUUid: `protected-user-modal${ this.userId }`,
      refreshKey: 'protected-user-modal',
      activateFormSettings: {
        addUrl: `/api/user/${  this.userId  }/activate`,
        primaryKey: 'id',
        formElements: {
          protected_indefinite: {
            type: 'checkbox_yes_no',
            value: false,
            defaultValue: false,
            data: {
              text: 'Indefinite'
            },
            errorClass: 'input-error',
            isValidated: false,
            isError: false,
            post_param: 'protected_indefinite',
            event: {
              change ($event, elementSettings, formSettings) {
                formSettings.formElements.protected_until.value = null
                formSettings.formElements.protected_completes.value = null
              }
            }
          },
          protected_until: {
            type: 'date',
            defaultValue: null,
            value: null,
            errorClass: 'input-error',
            isValidated: false,
            isError: false,
            refreshKey: 'protected_until',
            controlStyle: 'width: 200px;',
            validate (elementSettings, formSettings) {
              return true
            },
            post_param: 'protected_until',
            event: {
              input ($event, elementSettings, formSettings) {
                formSettings.formElements.protected_indefinite.value = false
                formSettings.formElements.protected_completes.value = null
              }
            }
          },
          protected_completes: {
            type: 'input',
            sub_type: 'number',
            defaultValue: null,
            value: null,
            errorClass: 'input-error',
            errorMessage: 'Protected information is invalid!',
            isValidated: false,
            isError: false,
            refreshKey: 'protected_completes',
            controlStyle: 'width: 200px;',
            post_param: 'protected_completes',
            place_holder: 'Number of completes',
            next_text: 'completes',
            validate (elementSettings, formSettings) {
              return isTestMode ||
                                formSettings.formElements.protected_indefinite.value ||
                                formSettings.formElements.protected_until.value !== null ||
                                parseInt(formSettings.formElements.protected_completes.value) > 0
            },
            event: {
              input ($event, elementSettings, formSettings) {
                formSettings.formElements.protected_indefinite.value = false
                formSettings.formElements.protected_until.value = null
              }
            },
            errorMessage: 'At least one of above setting cannot be empty!'
          },
          comment: {
            type: 'textarea',
            defaultValue: '',
            post_param: 'comment',
            value: '',
            errorClass: 'input-error',
            errorMessage: 'Internal comment is required!',
            isValidated: false,
            place_holder: 'Input internal comment',
            isError: false
          }
        },
        vueContainer: this
      }
    }
  },
  methods: {
    showForm () {
      this.$bvModal.show(this.modalUUid)

      this.$nextTick(() => {
        this.getFormVue().clearForm()
      })
    },
    successAddedUserBan (data) {
      this.$emit('activated', data.response)
      this.$bvModal.hide(this.modalUUid)
    },
    getFormVue () {
      return this.$refs.main_form
    }
  }
}
</script>

<style>
</style>
