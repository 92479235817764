import itemSchema from '@/store/modules/schemas/default.schema'
import ModuleBase from '@/store/modules/ModuleBase'
import axios from '@/main/axios'

const resourceName = 'cronjob-logs'
const base = ModuleBase(itemSchema, resourceName)
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
    ...{
      signature: null
    }
  },
  getters: {
    ...base.getters
  },
  mutations: {
    ...base.mutations
  },
  actions: {
    ...base.actions,
    getSignatureLogs: async (state, params) => {
      const url = `${ base.basePath + resourceName }/get-signature-logs`
      const {data} = await axios.get(url, {params})
      return data
    }
  }
}
