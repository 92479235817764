<template>
    <div>
        <ElementCheckBox :key="elementSettings.refreshKey" ref="checkbox" v-if="elementSettings.type === 'checkbox'" :elementSettings="elementSettings" :formSettings="formSettings"/>
        <ElementCheckBoxYesNo :key="elementSettings.refreshKey" ref="checkbox_yes_no" v-if="elementSettings.type === 'checkbox_yes_no'" :elementSettings="elementSettings" :formSettings="formSettings"/>
        <ElementInput :key="elementSettings.refreshKey" ref="input" v-if="elementSettings.type === 'input'" :elementSettings="elementSettings" :formSettings="formSettings"/>
        <Textarea :key="elementSettings.refreshKey" ref="textarea" v-if="elementSettings.type === 'textarea'" :elementSettings="elementSettings" :formSettings="formSettings"/>
        <ElementRadioButton :key="elementSettings.refreshKey" ref="radio" v-if="elementSettings.type === 'radio'" :elementSettings="elementSettings" :formSettings="formSettings"/>
        <ElementSelect :key="elementSettings.refreshKey" ref="select" v-if="elementSettings.type === 'select'" :elementSettings="elementSettings" :formSettings="formSettings"/>
        <TagInput :key="elementSettings.refreshKey" ref="tag_inputs" v-if="elementSettings.type === 'tag_inputs'" :elementSettings="elementSettings" :formSettings="formSettings"/>
        <ElementDate :key="elementSettings.refreshKey" ref="date" v-if="elementSettings.type === 'date'" :elementSettings="elementSettings" :formSettings="formSettings"/>
        <ImageInput :key="elementSettings.refreshKey" ref="image_input" v-if="elementSettings.type === 'image'" :elementSettings="elementSettings" :formSettings="formSettings"/>
        <HexColorInput :key="elementSettings.refreshKey" ref="color_input" v-if="elementSettings.type === 'color'" :elementSettings="elementSettings" :formSettings="formSettings" />
    </div>
</template>

<script>
import ElementCheckBox from './Checkbox.vue'
import ElementCheckBoxYesNo from './CheckboxYesNo.vue'
import ElementInput from './Input.vue'
import ElementRadioButton from './RadioButton.vue'
import ElementSelect from './Select.vue'
import Textarea from './Textarea.vue'
import TagInput from './TagInput.vue'
import ElementDate from './Date.vue'
import ImageInput from './ImageInput.vue'
import HexColorInput from '@/main/plugins/forms/HexColorInput.vue'

export default {
  components: {
    HexColorInput,
    ElementCheckBox,
    ElementCheckBoxYesNo,
    ElementInput,
    ElementRadioButton,
    ElementSelect,
    Textarea,
    TagInput,
    ElementDate,
    ImageInput
  },
  props: {
    elementSettings: {
      required: true,
      type: Object
    },
    formSettings: {
      required: true,
      type: Object
    }
  },
  methods: {
    executeElementValidate () {
      for (const refKey in this.$refs) {
        if (typeof this.$refs[refKey].executeElementValidate === 'function') {
          this.$refs[refKey].executeElementValidate()
        }
      }
    }
  },
  mounted () {
    //console.log(this.elementSettings);
  }
}
</script>

<style>

</style>
