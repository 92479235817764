<template>
  <div>
    <b-form-textarea
        v-model="elementSettings.value"
        rows="3"
        :placeholder="typeof elementSettings.place_holder === 'string' ? elementSettings.place_holder : ''"
        :class="errorClasses"
    ></b-form-textarea>
    <div class="text-error-notification" v-if="isError && typeof elementSettings.errorMessage === 'string' && elementSettings.isValidated">
      {{ elementSettings.errorMessage }}
    </div>
  </div>
</template>

<script>
import {BFormTextarea} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea
  },
  props: {
    elementSettings: {
      required: true,
      type: Object
    },
    formSettings: {
      required: true,
      type: Object
    }
  },
  methods: {
    executeElementValidate () {
      this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
    }
  },
  computed: {
    isError: {
      cache: false,
      get () {
        this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
        return this.elementSettings.isError
      }
    },
    errorClasses: {
      cache: false,
      get () {
        if (typeof this.elementSettings.errorClasses === 'string') {
          if (this.elementSettings.isValidated && this.isError) {
            return this.elementSettings.errorClasses
          }
          return ''
        } else {
          return ''
        }
      }
    }
  }
}
</script>

<style>

</style>
