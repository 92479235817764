<template>
  <b-container class="text-center">
    <table v-if="userStatusLogs.length" role="table" class="table-responsive table b-table table-striped table-hover my-2">
      <thead role="rowgroup" class="">
      <tr role="row">
        <th>New Status</th>
        <th>Initiator</th>
        <th>Comment</th>
        <th>Time</th>
      </tr>
      </thead>
      <tbody role="rowgroup">
      <tr v-for="log in userStatusLogs" :key="log.id">
        <td class="text-left">
          <UserStatusIdBadge :status_id="log.new_value" />
        </td>
        <td class="text-left">
          <UserBadgeName v-if="log.initiator" :name="log.initiator.name" :color="log.initiator.hex_color" />
          <span v-else>-</span>
        </td>
        <td class="text-left">{{ log.comment }}</td>
        <td class="text-left">
          {{ log.created_at | dotDateTime }}
        </td>
      </tr>
      </tbody>
    </table>
    <span v-else class="m-2">No Data</span>
  </b-container>
</template>

<script>

import UserStatusIdBadge from '@/views/users/UserDetails/UserStatusIdBadge.vue'
import UserBadgeName from "@/views/users/UserBadgeName.vue";

export default {
  components: {UserBadgeName, UserStatusIdBadge},
  props: {
    attributeLogs: {
      type: [Array, String],
      required: true
    }
  },
  computed: {
    userStatusLogs () {
      if (!Array.isArray(this.attributeLogs)) {
        return JSON.parse(this.attributeLogs)
      }

      return this.attributeLogs
    },
  },
}
</script>
