import axios from 'axios'

const baseURL = process.env.VUE_APP_CHAT_API_BASE_URL ? process.env.VUE_APP_CHAT_API_BASE_URL : 'https://chat-api.primeinsightsapp.dev'

const axiosChatObject = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
})

axiosChatObject.updateAuthentication = function (apiToken) {
  axiosChatObject.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`
}

axiosChatObject.removeAuthentication = function () {
  delete (axiosChatObject.defaults.headers.common['Authorization'])
}

axiosChatObject.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default axiosChatObject