<template>
<div>
    <b-modal
        :id="modalUUid"
        :ref="modalUUid"
        :title="`Add User ${userBanFormSettings.formElements.status.data.find(data => data.value === status).text}`"
        :hide-footer="true"
    >
        <BaseForm
            ref="main_form"
            :settings="userBanFormSettings"
            @success="successAddedUserBan"
            @cancel="$bvModal.hide(modalUUid)"
            v-slot="{ save, cancel }"
        >
            <b-alert :show="isNeedApproval" variant="warning" class="p-1">
              <b-icon icon="exclamation-circle" variant="warning" class="mr-50"></b-icon>
              <b class="text-warning">You need an approval for this action</b>
            </b-alert>

            <b-row class="mb-1 hidden">
                <b-col cols="3"> Type </b-col>
                <b-col cols="9">
                    <FormElement
                        ref="status"
                        :elementSettings="userBanFormSettings.formElements.status"
                        :formSettings="userBanFormSettings"
                    />
                </b-col>
            </b-row>
            <b-row style="display: flex; align-items: center; height: 45px;">
                <b-col cols="3"> Ban Expiry </b-col>
                <b-col
                    cols="9"
                    style="display: flex; align-items: center; gap: 20px"
                >
                    <FormElement
                        v-if="showBanIndefinite"
                        ref="ban_indefinite"
                        style="margin-bottom: 0"
                        :elementSettings="userBanFormSettings.formElements.ban_indefinite"
                        :formSettings="userBanFormSettings"
                    />
                    <FormElement
                        v-show="showBanExpiry"
                        ref="ban_expiry"
                        style="margin-bottom: 0"
                        :elementSettings="userBanFormSettings.formElements.ban_expiry"
                        :formSettings="userBanFormSettings"
                    />
                </b-col>
            </b-row>
            <b-row class="mb-1" v-show="showBanExpiry">
              <b-col :cols="showBanIndefinite ? 5 : 3"></b-col>
              <b-col>
                <div class="d-flex ml-1" style="gap: 10px;">
                  <a class="text-primary" @click="setBanExpiryDateInWeek(1)">1 Week</a>
                  <a class="text-primary" @click="setBanExpiryDateInWeek(2)">2 Weeks</a>
                  <a class="text-primary" @click="setBanExpiryDateInWeek(3)">3 Weeks</a>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col cols="3"> Internal Comment </b-col>
                <b-col cols="9">
                    <FormElement
                        ref="comment"
                        :elementSettings="userBanFormSettings.formElements.comment"
                        :formSettings="userBanFormSettings"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button variant="primary" @click="save()" class="mr-1">
                        Save
                    </b-button>
                    <b-button variant="warning" @click="cancel()"
                        >Cancel</b-button
                    >
                </b-col>
            </b-row>
        </BaseForm>
    </b-modal>
    </div>
</template>

<script>
export default {
  props:{
    userId: {
      required: true,
      type: Number
    },
    isNeedApproval: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number,
      default: 2
    }
  },
  data () {
    return {
      modalUUid: `user-ban-modal${ this.userId }`,
      userBanFormSettings: {
        addUrl: `/api/user/${  this.userId  }/ban`,
        primaryKey: 'id',
        formElements: {
          status: {
            type: 'radio',
            sub_type: 'hidden',
            defaultValue: this.status,
            rules: ['required'],
            value: this.status,
            data: [
              { value: 6, text: 'On Hold' },
              { value: 2, text: 'Regular Ban' },
              { value: 3, text: 'Shadow Ban' }
            ],
            errorClass: 'input-error',
            errorMessage: 'Action is invalid!',
            isValidated: false,
            isError: false,
            post_param: 'status'
          },
          ban_indefinite: {
            type: 'checkbox_yes_no',
            defaultValue: this.value === 3,
            value: this.value === 3,
            data: {
              text: 'Indefinite'
            },
            errorClass: 'input-error',
            isValidated: false,
            isError: false,
            post_param: 'ban_indefinite',
            event: {
              change ($event, elementSettings, formSettings) {
                formSettings.formElements.ban_expiry.value = null
              }
            }
          },
          ban_expiry: {
            type: 'date',
            defaultValue: null,
            value: null,
            errorClass: 'input-error',
            errorMessage: 'Ban expiry is invalid!',
            isValidated: false,
            isError: false,
            refreshKey: 'ban_expiry',
            validate (elementSettings, formSettings) {
              if (formSettings.formElements.ban_indefinite.value) return true
              return formSettings.formElements.ban_expiry.value !== null
            },
            post_param: 'ban_expiry',
            event: {
              input ($event, elementSettings, formSettings) {
                formSettings.formElements.ban_indefinite.value = false
              }
            }
          },
          comment: {
            type: 'textarea',
            defaultValue: '',
            rules: ['required'],
            post_param: 'comment',
            value: '',
            errorClass: 'input-error',
            errorMessage: 'Internal comment is required!',
            isValidated: false,
            place_holder: 'Input internal comment',
            isError: false
          }
        },
        vueContainer: this
      },
      showBanIndefinite: true,
      showBanExpiry: true
    }
  },
  methods: {
    showForm () {
      this.$bvModal.show(this.modalUUid)

      this.$nextTick(() => {
        this.getFormVue().clearForm()
        this.initDefaultForm(this.status)
      })
    },
    successAddedUserBan (data) {
      this.$emit('added', data)
      this.$bvModal.hide(this.modalUUid)
    },
    getFormVue () {
      return this.$refs.main_form
    },
    setBanExpiryDateInWeek (weeks) {
      const expiryDate = new Date()
      expiryDate.setDate(expiryDate.getDate() + (weeks * 7))
      this.userBanFormSettings.formElements.ban_expiry.value = expiryDate.toISOString().slice(0, 10)
    },
    initDefaultForm (value) {
      this.$nextTick(() => {
        this.$set(this.userBanFormSettings.formElements.status, 'value', value)

        this.$set(this.userBanFormSettings.formElements.ban_indefinite, 'disabled', value === 3)
        this.$set(this.userBanFormSettings.formElements.ban_indefinite, 'value', value === 3)

        this.$set(this, 'showBanIndefinite', value !== 6)
        this.$set(this, 'showBanExpiry', value !== 3)
      })
    }
  },
  watch: {
    'status': {
      handler (value) {
        this.initDefaultForm(value)
      },
      immediate: true
    }
  }
}
</script>

<style>
</style>
