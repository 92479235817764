<template>
  <div>
        <b-form-datepicker 
            :class="errorClasses"  
            v-model="elementSettings.value" 
            :readonly="elementSettings.readonly"
            :disabled="elementSettings.disabled" 
            @input="changeDate" 

        ></b-form-datepicker>
        <div class="text-error-notification" v-if="isError && typeof elementSettings.errorMessage === 'string' && elementSettings.isValidated">{{elementSettings.errorMessage}}</div>
  </div>
</template>
<script>
export default {
  props: {
    elementSettings: {
      required: true,
      type: Object
    },
    formSettings: {
      required: true,
      type: Object
    }
  },
  methods: {
    executeElementValidate () {
      this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
    },
    changeDate ($event) {
      if (this.elementSettings.event && typeof this.elementSettings.event.input === 'function') {
        this.elementSettings.event.input($event, this.elementSettings, this.formSettings)
      }
    }
  },
  computed: {
    isError: {
      cache: false,
      get () {
        this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
        return this.elementSettings.isError
      }
    },
    errorClasses: {
      cache: false,
      get () {
        if (typeof this.elementSettings.errorClasses === 'string') {
          if (this.elementSettings.isValidated && this.isError) {
            return this.elementSettings.errorClasses
          }
          return ''
        } else {
          return ''
        }
      }
    }
  }
}
</script>