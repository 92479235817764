<template>
  <div v-if="user" id="user_details_page">
    <div class="card">
      <quick-links v-if="shownAsModal" class="box-shadowed" :useActiveLink="false" />
      <b-card-header>
        <b-alert v-if="staffReviewer" :show="$store.state.auth.AppActiveAdmin.id !== staffReviewer.id" variant="warning" class="p-1 mb-2 border border-warning">
          <div class="d-flex text-dark align-items-center">
            <UserBadgeName :name="staffReviewer.name" :color="staffReviewer.hex_color" />
            <div class="text-bold pl-25">is looking at this user</div>
          </div>
        </b-alert>
        <div
            style="
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 20px;
          "
        >
          <div>
            <h3>User Details</h3>
            <table class="table b-table table-striped table-hover">
              <tbody>
              <tr>
                <td>User Id</td>
                <td>{{ user.id }}</td>
              </tr>
              <tr>
                <td>App</td>
                <td>{{ user.app_name }}</td>
              </tr>
              <tr v-if="user.is_po">
                <td>PO User ID</td>
                <td class="d-flex text-nowrap align-items-center" style="gap: 5px">
                  <b-img
                      :src="primeOpinionLogo"
                      alt="prime-opinion-logo"
                      class="mr-50"
                      width="20px"
                  ></b-img>
                  <POUserElement :id="user.po_user_id" :additionalText="user.po_user_deleted ? '(Deleted)' : ''" />
                </td>
              </tr>
              <tr v-if="user.is_fs">
                <td>FS User ID</td>
                <td class="d-flex text-nowrap align-items-center" style="gap: 5px">
                  <b-img
                      :src="fiveSurveysLogo"
                      alt="five-surveys-logo"
                      class="mr-50"
                      width="25px"
                  ></b-img>
                  <FSUserElement :id="user.fs_user_id" :additionalText="user.fs_user_deleted ? '(Deleted)' : ''" />
                </td>
              </tr>
              <tr v-if="user.is_hc">
                <td>HC User ID</td>
                <td class="d-flex text-nowrap align-items-center" style="gap: 5px">
                  <b-img
                      :src="heyCashLogo"
                      alt="five-surveys-logo"
                      class="mr-50"
                      width="25px"
                  ></b-img>
                  <HCUserElement :id="user.hc_user_id" :additionalText="user.hc_user_deleted ? '(Deleted)' : ''" />
                </td>
              </tr>
              <tr>
                <td>Platform</td>
                <td class="d-flex">
                  <PlatformDeviceIcon v-if="platformLastHundredClicks" :platform="platformLastHundredClicks" />
                  <span v-else></span>
                </td>
              </tr>
              <tr>
                <td>User Hash</td>
                <td>{{ user.hash }}</td>
              </tr>
              <tr>
                <td>External Id</td>
                <td v-if="showMinimalizeExternalId">
                  {{ user.external_user_id.substr(0, 50) }}<span v-if="user.external_user_id.length > 50" class="text-primary" @click="showMinimalizeExternalId = false">...</span>
                </td>
                <td v-else>{{ user.external_user_id }}</td>
              </tr>
              <tr>
                <td>External Source</td>
                <td>{{ user.external_source }}</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td class="d-flex align-items-center text-nowrap">
                  <template v-if="emailDetails.name && emailDetails.domain">
                    {{ emailDetails.name }}@<b-link @click="showFraudEmailDomains(emailDetails.domain)">{{ emailDetails.domain }}</b-link>
                  </template>
                  <b-badge v-if="isHighRiskEmailDomain" variant="danger">High-Risk Email</b-badge>
                </td>
              </tr>
              <tr>
                <td>Country</td>
                <td>
                  <CountryElement
                      :code="user.country"
                      :countries="countries"
                      :item="user"
                      :name="user.country_name"
                      :url="`/api/user/${user.id}/country`"
                      :withUpdateCountry="true"
                      @updated="updateCountry(user, $event)"
                  />
                </td>
              </tr>
              <tr>
                <td>Languages</td>
                <td @click="showEditLanguage = true">
                  <div v-show="!showEditLanguage">
                    {{
                      user.languages
                          .map((language) => language.language)
                          .join(", ")
                    }}
                  </div>
                  <div
                      v-if="showEditLanguage"
                      class="row d-flex align-items-center"
                  >
                    <v-select
                        v-model="user.languages"
                        :options="user.available_languages"
                        class="col-10"
                        label="language"
                        multiple
                    />
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="btn-icon"
                        title="Save"
                        variant="success"
                        @click="updateUserLanguages"
                    >
                      <feather-icon icon="CheckIcon"/>
                    </b-button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Interface Languages</td>
                <td @click="showEditInterfaceLanguage = true">
                  <div v-show="!showEditInterfaceLanguage">
                    {{ languageCodes[user.interface_language] }}
                  </div>
                  <div
                      v-if="showEditInterfaceLanguage"
                      class="row d-flex align-items-center"
                  >
                    <v-select
                        v-model="userInterfaceLanguage"
                        :options="Object.entries(languageCodes).map(([code, name]) => ({ code, name }))"
                        class="col-10"
                        label="name"
                        value="code"
                        placeholder="Select a language"
                    />
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="btn-icon"
                        title="Save"
                        variant="success"
                        @click="updateUserInterfaceLanguage"
                    >
                      <feather-icon icon="CheckIcon"/>
                    </b-button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td style="line-height: 20px; position: relative">
                  <div class="row">
                    <div class="col-11">
                      <template v-if="user.status === 1">
                        <b-badge style="font-size: 11px" variant="success"
                        >Active
                        </b-badge>
                        <template v-if="user.protected_status > 0">
                          <br/>
                          <span>
                              <b>Protect: </b>
                              <span v-if="user.protected_status === 2"
                              >until
                                {{
                                  user.protected_until | dotDateTimeShort
                                }}
                                (<span
                                    :class="
                                    user.status_left_time <= 2
                                      ? 'text-warning'
                                      : ''
                                  "
                                >{{ user.status_left_time }}</span
                                >
                                days left)</span
                              >
                              <span v-if="user.protected_status === 1"
                              >infinite</span
                              >
                              <span v-if="user.protected_status === 3">
                                For {{ user.protected_completes }} completes
                                (<span
                                  :class="
                                    user.completes_left <= 5
                                      ? 'text-warning'
                                      : ''
                                  "
                              >{{ user.completes_left }}</span
                              >
                                left)
                              </span>
                            </span>
                        </template>
                      </template>
                      <template v-if="user.status === 2">
                        <b-badge
                            style="font-size: 11px"
                            variant="danger"
                            @click="showProtectedStatusModal()"
                        >Banned
                        </b-badge>
                        <br/>
                        <b>Until: </b>
                        <span v-if="user.banned_until">
                            {{ user.banned_until | dotDateTime }}</span
                        >
                        <span v-else>Infinite</span>
                      </template>
                      <template v-if="user.status === 3">
                        <b-badge
                            class="user-status-3"
                            style="font-size: 11px"
                            @click="showProtectedStatusModal()"
                        >Shadow Banned
                        </b-badge>
                        <br/>
                        <b>Until: </b>
                        <span v-if="user.banned_until">
                            {{ user.banned_until | dotDateTime }}</span
                        >
                        <span v-else>Infinite</span>
                      </template>
                      <template v-if="user.status === 4">
                        <b-badge
                            style="font-size: 11px"
                            variant="warning"
                            @click="showProtectedStatusModal()"
                        >Review
                        </b-badge>
                      </template>
                      <template v-if="user.status === 5">
                        <b-badge
                            class="user-status-5"
                            style="font-size: 11px"
                            @click="showProtectedStatusModal()"
                        >Shadow Review
                        </b-badge>
                      </template>
                      <template v-if="user.status === 6">
                        <b-badge
                            class="user-status-6"
                            style="font-size: 11px"
                            @click="showProtectedStatusModal()"
                        >On Hold
                        </b-badge>
                        <br/>
                        <b>Until: </b>
                        <span v-if="user.banned_until">
                            {{ user.banned_until | dotDateTime }}</span
                        >
                        <span v-else>Infinite</span>
                      </template>
                      <template v-if="user.status_updated_at">
                        <br/>
                        <b>Last update: </b
                        >{{ user.status_updated_at | dotDateTime }}&nbsp;
                        <template v-if="user.status_by_admin_id">
                          <div class="d-inline-flex">
                            by &nbsp;
                            <UserBadgeName
                                :color="user.admin_hex_color"
                                :name="user.admin_name"
                            />
                          </div>
                        </template>
                      </template>
                      <template v-if="user.banned_message">
                        <br/>
                        <b>Comment: </b>{{ user.banned_message }}
                      </template>
                    </div>

                    <div class="col-1 p-0 d-flex align-items-center">
                      <b-icon
                          icon="journals"
                          scale="1.5"
                          @click="$refs['attribute_logs_modal'].show()"
                      ></b-icon>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td class="text-nowrap" style="padding-left: 0px">
                  Created
                  {{ user.created_at | dotDateTime }}
                </td>
                <td
                    class="text-nowrap"
                    style="padding-right: 0px; text-align: right"
                >
                  <div v-if="user.last_click">
                    Last click
                    {{ user.last_click.created_at | dotDateTime }}
                  </div>
                  <div v-else>
                    <i>No click data yet</i>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>

            <UserActionsBox
                v-if="id"
                :can_trigger_verification.sync="can_trigger_verification"
                :is-display-inline="true"
                :phone_verifications.sync="phone_verifications"
                :user.sync="user"
                class="mt-2"
            />
          </div>
          <div style="min-width: 20%">
            <div>
              <h3>Performance</h3>
              <table
                  class="table b-table table-striped table-hover"
                  style="min-width: 250px"
              >
                <tbody>
                <tr>
                  <td>Clicks</td>
                  <td>{{ performance.total_clicks }}</td>
                </tr>
                <tr>
                  <td>Completes</td>
                  <td>{{ performance.total_completes }}</td>
                </tr>
                <tr>
                  <td>Conversion</td>
                  <td>{{ performance.conversion | round2Decimal }} %</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-5">
              <h3>Other Accounts</h3>
              <table
                  class="table b-table table-striped table-hover"
                  style="min-width: 250px"
              >
                <thead>
                <tr>
                  <th>App</th>
                  <th>App User ID</th>
                  <th>PS User ID</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(account, iOtherAccount) in otherAccounts"
                    :key="iOtherAccount"
                >
                  <td>
                    <div class="brand-logo">
                      <b-img
                          v-if="account.source === 'primeopinion'"
                          :src="primeOpinionLogo"
                          alt="prime-opinion-logo"
                          width="20px"
                      ></b-img>
                      <b-img
                          v-else-if="account.source === 'fivesurveys'"
                          :src="fiveSurveysLogo"
                          alt="five-surveys-logo"
                          width="25px"
                      ></b-img>
                      <b-img
                          v-else-if="account.source === 'heycash'"
                          :src="heyCashLogo"
                          alt="hey-cash-logo"
                          width="25px"
                      ></b-img>
                    </div>
                  </td>
                  <td>
                    <POUserElement
                        v-if="account.source === 'primeopinion'"
                        :id="account.id"
                    />
                    <FSUserElement
                        v-if="account.source === 'fivesurveys'"
                        :id="account.id"
                    />
                    <HCUserElement
                        v-if="account.source === 'heycash'"
                        :id="account.id"
                    />
                  </td>
                  <td>
                    <UserElement
                        :id="account.ps_id"
                        :status="account.status"
                    />
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-5">
              <h3>Related Accounts</h3>
              <table
                class="table b-table table-striped table-hover"
                style="min-width: 250px"
              >
                <thead>
                  <tr>
                    <th>Accounts</th>
                    <th>Banned</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(account, iRelatedAccount) in relatedAccounts" :key="iRelatedAccount">
                    <td>
                      <div class="">
                        <template v-if="account.total_users > 0">
                          <b-link @click="showRelatedAccountData">{{ account.total_users }}</b-link>
                        </template>
                        <template v-else>
                          {{ account.total_users  }}
                        </template>
                      </div>
                    </td>
                    <td :class="account.flagged_rate && parseFloat(account.flagged_rate) >= 0.2 ? 'bg-danger' : ''">
                      {{ account.flagged_users ? account.flagged_users : 0 }}
                      &nbsp;({{ account.flagged_rate ? account.flagged_rate : 0 | percentageRound }})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>
          <div style="min-width: 33%">
            <div
                class="mb-1"
                style="display: flex; justify-content: space-between; gap: 20px"
            >
              <div style="min-width: 45%">
                <h3>Settings</h3>
                <table
                    class="table b-table table-striped table-hover"
                    style="min-width: 250px"
                >
                  <tbody>
                  <tr>
                    <td>Research Defender</td>
                    <td>
                      <b-form-checkbox
                          v-model="user.research_defender"
                          switch
                          @change="changeStatus('research_defender')"
                      ></b-form-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td>VPN/Proxy Check</td>
                    <td>
                      <b-form-checkbox
                          v-model="user.vpn_proxy_check"
                          switch
                          @change="changeStatus('vpn_proxy_check')"
                      ></b-form-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td>IP Location Check</td>
                    <td>
                      <b-form-checkbox
                          v-model="user.ip_location_check"
                          switch
                          @change="changeStatus('ip_location_check')"
                      ></b-form-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td>Payout Adjustment</td>
                    <td>
                      <b-input-group append="%" style="width: 100px">
                        <b-form-input
                            v-model="user.payout_adjustment"
                            :state="payoutAdjustmentValidate"
                            max="150"
                            min="50"
                            type="number"
                            @change="updatePayoutAdjustment($event)"
                            @input="userPayoutChanged = true"
                        ></b-form-input>
                      </b-input-group>
                    </td>
                  </tr>
                  <tr>
                    <td>Min Pub. Payout</td>
                    <td>
                      <b-input-group prepend="$" style="width: 100px">
                        <b-form-input
                            v-model="user.min_publisher_payout"
                            :disabled="!isCurrentUserSupremeSuperAdminOrQA"
                            type="number"
                            @change="updateMinPublisherPayout($event)"
                        ></b-form-input>
                      </b-input-group>
                    </td>
                  </tr>
                  <tr>
                    <td>Max Recon. Buyers</td>
                    <td>
                      <b-input-group append="%" style="width: 100px">
                        <b-form-input
                            v-model="user.max_reconciliation_buyers"
                            :disabled="!isCurrentUserSupremeSuperAdminOrQA"
                            :state="maxReconciliationBuyersValidate"
                            max="150"
                            type="number"
                            @change="updateMaxReconciliationBuyers($event)"
                            @input="userMaxReconciliationBuyers = true"
                        ></b-form-input>
                      </b-input-group>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="text-center mt-1">
                  <b-button
                      :disabled="!user.birthday"
                      variant="outline-danger"
                      @click="showConfirmResetBirthdate"
                  >Reset birthdate
                  </b-button>
                </div>
              </div>
            </div>
            <div>
              <textarea
                  v-model="user.comment"
                  class="form-control"
                  placeholder="User comment"
                  style="min-height: 100px; margin-bottom: 5px"
                  @blur="autoSaveComment"
              ></textarea>
              <div v-if="user.comment_updated_at" class="d-flex float-right">
                Last updated at:
                <b>{{ user.comment_updated_at | dotDateTime }}</b>
                <template v-if="user.comment_updated_admin_id">
                  <div class="d-inline-flex ml-4">
                    <UserBadgeName
                        :color="user.comment_admin_hex_color"
                        :name="user.comment_admin_name"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <UserActionsBox
              v-if="!id"
              :can_trigger_verification.sync="can_trigger_verification"
              :phone_verifications.sync="phone_verifications"
              :user.sync="user"
          />
        </div>
      </b-card-header>
    </div>
    <div class="card">
      <b-card-header>
        <div
            style="
            display: flex;
            justify-content: flex-start;
            width: 100%;
            gap: 5%;
          "
        >
          <div style="min-width: 27%">
            <h3>Quality</h3>
            <div
                v-if="quality.is_suspicious_activity_pattern"
                class="bg-danger text-white p-50 my-50 mr-50"
                style="display: inline-block"
            >
              Suspicious Activity Pattern
            </div>
            <div
                v-if="quality.is_suspicious_click_pattern"
                class="bg-danger text-white p-50 my-50"
                style="display: inline-block"
            >
              Suspicious Click Pattern
            </div>
            <table class="table b-table table-striped table-hover">
              <tbody>
              <tr>
                <td class="text-nowrap">
                  <router-link
                    :to="{
                        name: 'clicks',
                        query: { 'filter[user_id]': user.id, 'filter[suspicious_response_codes]': true },
                      }"
                  >
                    Suspicious Response Codes
                  </router-link>
                </td>
                <template v-if="quality">
                  <td :class="classSuspiciousResponseCode">
                    {{ quality.suspicious_response_percentage.toFixed(1) }} %
                    ({{ quality.suspicious_response_count }})
                  </td>
                </template>
                <td v-else class="text-nowrap">No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">Avg Publisher CPI</td>
                <template v-if="quality.click_additional_metrics">
                  <td :class="classAvgPublisherCPI" class="text-nowrap">
                    {{ quality.click_additional_metrics.avg_cpi | formatCurrency }}
                  </td>
                </template>
                <td v-else class="text-nowrap">No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">Earnings per Click</td>
                <template v-if="quality.click_additional_metrics">
                  <td :class="classEarningPerClick" class="text-nowrap">
                    {{ quality.click_additional_metrics.earnings_per_click | formatCurrency }}
                  </td>
                </template>
                <td v-else class="text-nowrap">No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">Time in Completes</td>
                <template v-if="quality.click_additional_metrics">
                  <td :class="classTimeInCompletes" class="text-nowrap">
                    {{ quality.click_additional_metrics.time_in_completes_percentage | percentageRound }}
                  </td>
                </template>
                <td v-else class="text-nowrap">No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">Overlapping Completes</td>
                <template v-if="quality.overlap_rate">
                  <td :class="classOverlappingCompletes" class="text-nowrap">
                    {{ quality.overlap_rate | percentageRound }}
                  </td>
                </template>
                <td v-else class="text-nowrap">No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">Completes Factor</td>
                <td :class="classCompletesFactor" class="text-nowrap">
                  {{ completesFactor.total }}
                  <template v-if="completesFactor.total_alert > 0">
                    ({{ completesFactor.total_alert }} alerts)
                  </template>
                </td>
              </tr>
              <tr>
                <td class="text-nowrap">Median TCP Latency</td>
                <template
                  v-if="
                      quality && quality.the_second_median_tcp_latency != null
                    "
                >
                  <td
                    :class="classTheSecondMedianTcpLatency"
                    class="text-nowrap"
                  >
                    {{
                      parseFloat(quality.the_second_median_tcp_latency)
                        | round2Decimal
                    }}
                    ms
                  </td>
                </template>
                <td v-else>No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">
                  <router-link
                      :to="{
                        name: 'latency_logs',
                        query: { 'filter[userId]': user.id },
                      }"
                  >
                    Median WS Latency
                  </router-link>
                </td>
                <template
                    v-if="
                      quality && quality.the_second_median_ws_latency != null
                    "
                >
                  <td
                      :class="classTheSecondMedianWSLatency"
                      class="text-nowrap"
                  >
                    {{
                      parseFloat(quality.the_second_median_ws_latency)
                          | round2Decimal
                    }}
                    ms
                  </td>
                </template>
                <td v-else>No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">
                  <router-link
                      :to="{
                        name: 'latency_logs',
                        query: { 'filter[userId]': user.id },
                      }"
                  >
                    Median Latency Delta
                  </router-link>
                </td>
                <template
                    v-if="
                      quality && quality.the_second_median_latency_delta != null
                    "
                >
                  <td
                      :class="classTheSecondMedianLatencyDelta"
                      class="text-nowrap"
                  >
                    {{
                      parseFloat(quality.the_second_median_latency_delta)
                          | round2Decimal
                    }}
                    ms
                  </td>
                </template>
                <td v-else>No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">
                  <router-link
                      :to="{
                        name: 'latency_logs',
                        query: { 'filter[userId]': user.id },
                      }"
                  >
                    Latency Results
                  </router-link>
                </td>
                <template
                    v-if="quality && quality.the_second_latency_results != null"
                >
                  <td
                      :class="classTheSecondLatencyResults"
                      class="text-nowrap"
                  >
                    {{ quality.the_second_latency_results | percentageRound }}
                  </td>
                </template>
                <td v-else>No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">
                  <router-link
                      :to="{
                        name: 'device_results',
                        query: { 'filter[userId]': user.id },
                      }"
                  >
                    Device OS Mismatch
                  </router-link>
                </td>
                <template
                    v-if="
                      quality && quality.device_os_mismatch_percentage != null
                    "
                >
                  <td :class="classDeviceOsMismatch" class="text-nowrap">
                    {{
                      quality.device_os_mismatch_percentage | percentageRound
                    }}
                  </td>
                </template>
                <td v-else>No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">
                  <router-link
                      :to="{
                        name: 'timezone_results',
                        query: { 'filter[userId]': user.id },
                      }"
                  >
                    Max Time Delta
                  </router-link>
                </td>
                <template v-if="quality && quality.max_time_delta != null">
                  <td :class="classMaxTimeDelta" class="text-nowrap">
                    {{ quality.max_time_delta }} hours
                  </td>
                </template>
                <td v-else>No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">
                  <router-link
                      :to="{
                        name: 'timezone_results',
                        query: { 'filter[userId]': user.id },
                      }"
                  >
                    Timezone Mismatch
                  </router-link>
                </td>
                <template v-if="quality && quality.timezone_mismatch.length">
                  <td :class="classTimezoneMismatch">
                    {{ quality.timezone_mismatch.join(", ") }}
                  </td>
                </template>
                <td v-else class="bg-success text-white">-</td>
              </tr>
              <tr>
                <td class="text-nowrap">
                  <router-link
                      :to="{
                        name: 'ip_logs',
                        query: { 'filter[userId]': user.id },
                      }"
                  >
                    Country Mismatch
                  </router-link>
                </td>
                <template v-if="quality && quality.country_mismatch.length">
                  <td :class="classCountryMismatch">
                    {{ quality.country_mismatch.join(", ") }}
                  </td>
                </template>
                <td v-else class="bg-success text-white">-</td>
              </tr>
              <tr>
                <td class="text-nowrap">Pattern Risk</td>
                <template v-if="quality && quality.pattern_risk != null">
                  <td :class="classPatternRisk" class="text-nowrap">
                      <span
                          v-if="quality.pattern_risk.fraud_rate_total === null"
                      >No Data</span
                      >
                    <span
                        v-else-if="quality.pattern_risk.fraud_rate_48h === null"
                    >
                        {{
                        quality.pattern_risk.fraud_rate_total | percentage
                      }}
                        (48h: No Data)
                      </span>
                    <span v-else>
                        {{
                        quality.pattern_risk.fraud_rate_total | percentage
                      }}
                        (48h:
                        {{ quality.pattern_risk.fraud_rate_48h | percentage }})
                      </span>
                  </td>
                </template>
                <td v-else class="text-nowrap">No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">Supplier Bans</td>
                <template
                    v-if="supplier_statistics && supplier_statistics.length"
                >
                  <td :class="classSupplierBansQuality" class="text-nowrap">
                    {{
                      supplier_statistics.filter(
                          (supplier) => supplier.user_status === "banned"
                      ).length
                    }}
                  </td>
                </template>
                <td v-else>No result</td>
              </tr>
              <tr>
                <td class="text-nowrap">Detected Languages</td>
                <template v-if="quality && quality.detected_languages">
                  <td class="bg-danger text-white">
                    {{ quality.detected_languages }}
                  </td>
                </template>
                <td v-else class="bg-success text-white text-nowrap">
                  No result
                </td>
              </tr>
              <tr>
                <td class="text-nowrap">RDP Usage</td>
                <template
                    v-if="
                      quality &&
                      quality.ipqs_rdp_data &&
                      quality.ipqs_rdp_data.rdp_usage
                    "
                >
                  <td :class="classRDPResult">
                    {{ quality.ipqs_rdp_data.rdp_usage }} %
                  </td>
                </template>
                <td v-else class="bg-success text-white text-nowrap">
                  No result
                </td>
              </tr>
              <tr>
                <td class="text-nowrap">IPQS Score</td>
                <template
                    v-if="
                      quality &&
                      quality.ipqs_rdp_data &&
                      quality.ipqs_rdp_data.ipqs_unique_ips_avg
                    "
                >
                  <td :class="classIPQSScore">
                    {{ parseInt(quality.ipqs_rdp_data.ipqs_unique_ips_avg) }}
                  </td>
                </template>
                <td v-else class="bg-success text-white text-nowrap">
                  No result
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div style="min-width: 25%">
            <div class="mb-3">
              <h3>Reconciliations</h3>
              <table
                  class="table b-table table-striped table-hover"
                  style="max-width: 160px"
              >
                <thead>
                <tr>
                  <th>Month</th>
                  <th>Stats</th>
                  <th>Current</th>
                  <th>Projected</th>
                  <th>Confidence</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(
                      reconciliation, iReconciliation
                    ) in reconciliation.data"
                    :key="iReconciliation"
                >
                  <td style="width: 60px">{{ reconciliation.month }}</td>
                  <td class="text-nowrap" style="width: 45px">
                      <span class="text-danger">{{
                          reconciliation.reconciled_count
                        }}</span>
                    <span>/ {{ reconciliation.completes_count }}</span>
                    <span
                        v-if="reconciliation.high_recon_surveys_excluded > 0"
                    >
                        <b-icon
                            :id="`tooltip-high-recon-surveys-excluded-${iReconciliation}`"
                            class="border border-black mb-25 ml-50"
                            icon="star-fill"
                            scale=".85"
                        ></b-icon>
                        <b-tooltip
                            :target="`tooltip-high-recon-surveys-excluded-${iReconciliation}`"
                            triggers="hover"
                        >
                          <div class="font-small-4">
                            {{
                              reconciliation.high_recon_surveys_excluded
                            }}
                            surveys excluded for high recon
                          </div>
                        </b-tooltip>
                      </span>
                  </td>
                  <td
                      :class="
                        getClassReconciliationPercentage(
                          reconciliation.current * 100
                        )
                      "
                  >
                      <span>
                        {{ reconciliation.current | percentageRound }}</span
                      >
                  </td>
                  <td
                      v-if="reconciliation.is_show_projected"
                      :class="
                        getClassReconciliationPercentage(
                          reconciliation.projected * 100
                        )
                      "
                      class="text-nowrap"
                  >
                      <span v-if="reconciliation.projected"
                      >≈
                        {{ reconciliation.projected | percentageRound }}</span
                      >
                  </td>
                  <td v-else></td>
                  <td
                      v-if="reconciliation.is_show_projected"
                      :class="
                        getConfidence(
                          reconciliation.avg_days_since_complete
                        ).cssClass
                      "
                      class="text-nowrap"
                  >
                      <span v-if="reconciliation.avg_days_since_complete"
                      >
                        {{ getConfidence(reconciliation.avg_days_since_complete).text }}</span
                      >
                  </td>
                  <td v-else></td>
                </tr>
                <tr v-if="reconciliation.total > 0">
                  <td class="text-nowrap" colspan="2">
                    <router-link
                        :to="{
                          name: 'completes',
                          query: {
                            'filter[userId]': user.id,
                            'filter[dateRangeFrom]':
                              reconciliation.data[0].first_day_of_month,
                            'filter[status]': 2,
                          },
                        }"
                    >
                      Check Reconciliations
                    </router-link>
                  </td>
                  <td
                      :class="
                        getClassReconciliationPercentage(reconciliation.total)
                      "
                      class="text-nowrap text-center"
                      colspan="2"
                  >
                    {{ reconciliation.total }} %
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <h3 class="mb-1">Quality Questions</h3>

            <div v-for="(qualityQuestion, iQualityQuestion)  in quality_questions" :key="iQualityQuestion.id" class="mb-2">
              <div v-if="qualityQuestion.open_end">
                <h5>{{ qualityQuestion.open_end.question_text }}</h5>

                <div v-if="qualityQuestion.open_end.answer_text.includes('/cp')" class="text-right mb-50"><span class="p-50 bg-danger font-weight-bold">Copy/Pasted</span></div>
                <div
                    style="
                      padding: 5px 10px;
                      border: 1px solid #c3c3c3;
                      min-height: 60px;
                    "
                >
                  {{ qualityQuestion.open_end.answer_text.replace('/cp', '') }}
                </div>

                <div class="row">
                  <div class="col-4 d-flex align-items-center">
                    <div
                      v-if="qualityQuestion.open_end.duplicate_count || qualityQuestion.open_end.similar_count"
                      class="text-primary text-nowrap cursor-pointer d-flex align-items-center mb-2"
                      @click="showSimilarUserProfiling(qualityQuestion.open_end.user_profiling_id)"
                    >
                      <span class="mr-1"><b>{{ qualityQuestion.open_end.duplicate_count }}</b> Identical</span>
                      <span><b>{{ qualityQuestion.open_end.similar_count }}</b> Similar</span>
                    </div>
                    <span v-else class="text-nowrap d-flex align-items-center mb-2">No similar and duplicate</span>
                  </div>
                  <div class="col-8 d-flex align-items-center">
                    <div class="col-6 mb-2 font-weight-bold d-flex align-items-center">Banned Open End Duplicates</div>
                    <div v-if="qualityQuestion.open_end.similar_count > 0 || qualityQuestion.open_end.duplicate_count > 0"
                         :class="getClassBannedUserDuplicates(qualityQuestion.open_end)"
                         class="col-6 p-50 mb-2 d-flex align-items-center"
                    >
                      {{ qualityQuestion.open_end.banned_users_count_percentage | percentage }}
                      ({{ qualityQuestion.open_end.banned_users_duplicate_count }})
                    </div>
                    <div v-else class="p-50 mb-2 d-flex align-items-center">-</div>
                  </div>
                </div>
              </div>

              <div v-else>
                <h5>{{ qualityQuestion.question_text }}?</h5>

                <div>
                  <span v-for="(answer, iAnswer) in qualityQuestion.answers" :key="iAnswer" :class="{'text-primary': answer.is_answered}"><span v-if="iAnswer > 0">, </span>{{ answer.answer_text }}</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3>SMS Verification</h3>
            <table
                v-for="phone_verification in phone_verifications"
                :key="phone_verification.phone"
                class="table b-table table-striped table-hover mb-1"
            >
              <tbody>
              <tr :key="phone_verification.phone" class="mb-1">
                <td
                    class="text-center text-nowrap"
                    rowspan="4"
                    style="width: 130px"
                >
                  <div class="block mb-1">
                    {{ phone_verification.phone || "---" }}
                  </div>
                  <b-badge
                      v-if="phone_verification.verified"
                      style="font-size: 11px"
                      variant="success"
                  >
                    Verified
                  </b-badge>
                  <b-badge
                      v-if="!phone_verification.verified"
                      style="font-size: 11px"
                      variant="secondary"
                  >
                    Not verified
                  </b-badge>
                  <div class="mt-2">{{ phone_verification.carrier }}</div>
                </td>
                <td class="text-nowrap">Verification Delay</td>
                <td
                    :class="getClassVerificationDelay(phone_verification.delay)"
                    class="text-nowrap"
                >
                    <span v-if="phone_verification.delay">
                      {{ phone_verification.delay | toHourMinuteSecond }}
                    </span>
                  <span v-else>---</span>
                </td>
              </tr>

              <tr>
                <td class="text-nowrap">Copy/Pasted</td>
                <td
                    v-if="phone_verification.phone"
                    :class="
                      phone_verification.is_copy_pasted
                        ? 'bg-danger'
                        : 'bg-success'
                    "
                    class="text-nowrap"
                >
                    <span class="text-white">{{
                        phone_verification.is_copy_pasted ? "True" : "False"
                      }}</span>
                </td>
                <td v-else></td>
              </tr>

              <tr>
                <td class="text-nowrap">IPQS</td>
                <td
                    v-if="phone_verification.phone"
                    :class="getClassIpqsScore(phone_verification.ipqs_score)"
                    class="text-nowrap"
                >
                  {{ phone_verification.ipqs_score }}
                </td>
                <td v-else></td>
              </tr>

              <tr>
                <td
                    class="text-nowrap"
                    :class="{'cursor-pointer text-primary': phone_verification.phone}"
                    @click="phone_verification.phone ? showUsersByCarrier(phone_verification.carrier) : false"
                >
                  Carrier Risk
                </td>
                <td
                    v-if="phone_verification.phone"
                    :class="
                      getClassCarrierRisk(phone_verification.banned_percentage)
                    "
                    class="text-nowrap"
                >
                  {{ phone_verification.banned_percentage | percentageRound }}
                </td>
                <td v-else></td>
              </tr>
              </tbody>
            </table>
            <div class="bg-secondary text-white p-1" v-if="!phone_verifications.length && !user.app_supports_verification">
              Verification not supported for this app
            </div>
          </div>
        </div>
        <div
            v-if="userDuplicate.data.length > 0"
            class="mt-2"
            style="
            display: flex;
            justify-content: flex-start;
            width: 100%;
            gap: 5%;
          "
        >
          <div style="min-width: 15%">
            <h3>Duplicates</h3>
            <template>
              <table class="table b-table table-striped table-hover">
                <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader">User ID</th>
                  <th role="columnheader">Overlap</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="user_duplicate in userDuplicate.data"
                    :key="user_duplicate.id"
                >
                  <td>
                    <UserElement
                        :id="user_duplicate.id"
                        :status="user_duplicate.status"
                    />
                  </td>
                  <td>{{ user_duplicate.overlap }}</td>
                </tr>
                </tbody>
                <tfoot v-if="userDuplicate.isShowLink">
                <tr>
                  <td colspan="2">
                    <a
                        class="text-primary"
                        @click.prevent="showAllUserDuplicates"
                    >
                      Show {{ userDuplicate.IpOverlapCount }} more IP
                      duplicates
                    </a>
                  </td>
                </tr>
                </tfoot>
              </table>
            </template>
          </div>
        </div>
      </b-card-header>
    </div>

    <div class="card">
      <b-card-header style="padding-bottom: 5px">
        <h3>Supplier Statistics</h3>
      </b-card-header>
      <div>
        <table class="table b-table table-striped table-hover">
          <thead role="rowgroup">
          <tr role="row">
            <th aria-colindex="1" aria-sort="descending" role="columnheader">
              Supplier
            </th>
            <th aria-colindex="2" aria-sort="none" role="columnheader">
              Status
            </th>
            <th aria-colindex="2" aria-sort="none" role="columnheader">
              Banned until
            </th>
            <th
                aria-colindex="3"
                aria-sort="none"
                role="columnheader"
                style="width: 180px"
            >
              Bans
            </th>
            <th
                aria-colindex="3"
                aria-sort="none"
                role="columnheader"
                style="width: 180px"
            >
              Resets
            </th>
            <th aria-colindex="4" aria-sort="none" role="columnheader">
              Clicks
            </th>
            <th aria-colindex="4" aria-sort="none" role="columnheader">
              Completes
            </th>
            <th aria-colindex="4" aria-sort="none" role="columnheader">
              Conversion
            </th>
            <th aria-colindex="4" aria-sort="none" role="columnheader">
              Factor
            </th>
            <th
                aria-colindex="4"
                aria-sort="none"
                class="text-nowrap"
                role="columnheader"
            >
              Buyer Bans
            </th>
            <th aria-colindex="4" aria-sort="none" role="columnheader">
              Actions
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, key) in supplier_statistics" :key="key">
            <td>
              <SupplierElement :id="item.id" :name="item.name"/>
            </td>
            <td>
              <b-badge
                  v-if="item.is_disabled_by_user || item.user_status === 'disabled'"
                  style="font-size: 11px"
                  variant="secondary"
                  @click="activateSupplierAgain(item)"
                  class="cursor-pointer"
              >Disabled
              </b-badge>
              <b-badge
                  v-else-if="item.user_status === 'active'"
                  style="font-size: 11px"
                  variant="success"
              >Active
              </b-badge>
              <b-badge
                  v-else-if="item.user_status === 'banned'"
                  style="font-size: 11px"
                  variant="danger"
                  @click="activateSupplierAgain(item)"
                  class="cursor-pointer"
              >Banned
              </b-badge>
            </td>
            <td>
              <template v-if="item.user_status !== 'active'">
                <template v-if="item.banned_until === null"
                >Infinite
                </template
                >
                <template v-else>{{
                    item.banned_until | dotDateTime
                  }}
                </template>
              </template>
            </td>
            <td>
                <span
                    v-if="item.user_bans.length > 0"
                    class="text-primary"
                    @click="showListUserBanModal(item)"
                >{{ item.user_bans.length }} ban(s)</span
                >
            </td>
            <td>
                <span
                    v-if="item.reset_hashes.length > 0"
                    class="text-danger"
                    @click="showResetHashesModal(item)"
                >{{ item.reset_hashes.length }} reset(s)</span
                >
            </td>
            <td>{{ item.total_clicks }}</td>
            <td>
                <span class="text-danger">{{
                    item.total_reconciliations
                  }}</span>
              /
              {{ item.total_completes }}
            </td>
            <td>{{ item.conversion | round2Decimal }} %</td>
            <td :class="getSupplierFactorClass(item)">
              {{ item.factor }}
            </td>
            <td class="text-nowrap">
                <span
                    v-if="item.banned_buyers_count > 0"
                    class="text-primary"
                    @click="showUserBuyerBans(item)"
                >{{ item.banned_buyers_count }} ban(s)</span
                >
            </td>
            <td class="d-flex text-nowrap" style="gap: 3px">
              <button
                  class="btn btn-danger btn-sm"
                  type="button"
                  @click="getSupplierBanFormVue().showForm(item.id, 5)"
              >
                Ban
              </button>
              <button
                  class="btn btn-secondary btn-sm"
                  type="button"
                  @click="getSupplierBanFormVue().showForm(item.id, 6)"
              >
                Disable
              </button>
              <button
                  class="btn btn-warning btn-sm"
                  type="button"
                  @click="resetUserDataOnSupplier(item.id)"
              >
                Reset
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-card>
      <div class="p-chart-container">
        <div class="p-chart-header">
          Click Completes and Conversion Rates in 30 days
        </div>
        <vue-apex-charts
            :options="completesAndConversionsChartOptions"
            :series="completes_and_conversion_rates.series"
            height="450"
            type="line"
        ></vue-apex-charts>
      </div>
    </b-card>
    <ListUserBanModal :id="userId" ref="list_user_ban_modal"/>
    <ResetHashesModal :id="userId" ref="reset_hashes_modal"/>
    <b-modal ref="user_buyer_bans_modal" size="lg">
      <UserBuyerBans :userBuyerBans="currentBuyerBans"/>
    </b-modal>
    <b-modal ref="similar_user_profiling_modal" size="lg">
      <SimilarUserProfiling :similarUserProfilings="similarUserProfilings"/>
    </b-modal>
    <b-modal ref="user_domain_fraud" size="lg">
      <FraudEmailDomain :emailDomainFrauds="emailDomainFrauds"/>
    </b-modal>
    <b-modal ref="user_related_account_data" size="lg">
      <RelatedAccountData :relatedAccountData="relatedAccountData"/>
    </b-modal>
    <b-modal
        ref="user_profiling_basics_modal"
        size="lg"
        title="User Profiling Basics"
    >
      <UserProfilingBasics :userProfilingBasics="userProfilingBasics"/>
    </b-modal>
    <SupplierBanModal
        ref="supplier_ban_form"
        :userId="userId"
        @added="addedSupplierUserBanned"
    />
    <ProtectedUserStatusModal
        ref="protected_user_status"
        :isNeedApproval="false"
        :userId="userId"
        :status="user.status"
        @activated="activatedUser"
    />
    <b-modal
        ref="attribute_logs_modal"
        :hide-footer="true"
        :hide-header="true"
        centered
        size="lg"
    >
      <UserStatusLogModalContent :attributeLogs="user.attribute_logs"/>
    </b-modal>
    <b-modal ref="usersByCarrier" hide-footer size="md">
      <UsersByCarrier :carrier="currentCarrier"/>
    </b-modal>
  </div>
</template>

<script>
import ListUserBanModal from './UserDetails/ListUserBanModal.vue'
import ResetHashesModal from './UserDetails/ResetHashesModal.vue'
import UserBuyerBans from './UserDetails/UserBuyerBans.vue'
import SupplierBanModal from './UserDetails/SupplierBanModal.vue'
import ProtectedUserStatusModal from './UserDetails/ProtectedUserStatusModal.vue'
import VueApexCharts from 'vue-apexcharts'
import UserBadgeName from '@/views/users/UserBadgeName.vue'
import UserStatusLogModalContent from '@/views/users/UserDetails/UserStatusLogModalContent.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SimilarUserProfiling from '@/views/users/UserDetails/SimilarUserProfiling.vue'
import UserProfilingBasics from '@/views/users/UserDetails/UserProfilingBasics.vue'
import UsersByCarrier from '@/views/users/UserDetails/UsersByCarrier.vue'
import {$themeConfig} from '@themeConfig'
import POUserElement from '@/views/prime_opinion/elements/POUserElement.vue'
import FSUserElement from '@/views/five_surveys/elements/FSUserElement.vue'
import UserActionsBox from '@/views/users/UserDetails/UserActionsBox.vue'
import QuickLinks from '@/main/custom-components/QuickLinks.vue'
import store from '@/store'
import PlatformDeviceIcon from '@/main/custom-components/PlatformDeviceIcon.vue'
import FraudEmailDomain from '@/views/users/UserDetails/FraudEmailDomain.vue'
import RelatedAccountData from '@/views/users/UserDetails/RelatedAccountData.vue'
import HCUserElement from '@hc_views/elements/HCUserElement.vue'

export default {
  name: 'UserDetails',
  props: ['id', 'status', 'shownAsModal'],
  components: {
    HCUserElement,
    RelatedAccountData,
    FraudEmailDomain,
    PlatformDeviceIcon,
    QuickLinks,
    UserActionsBox,
    FSUserElement,
    POUserElement,
    UsersByCarrier,
    UserProfilingBasics,
    SimilarUserProfiling,
    UserStatusLogModalContent,
    UserBadgeName,
    VueApexCharts,
    ListUserBanModal,
    UserBuyerBans,
    SupplierBanModal,
    ProtectedUserStatusModal,
    ResetHashesModal,
    vSelect
  },
  directives: {
    Ripple
  },
  emits: ['update-user-settings'],
  data () {
    return {
      primeOpinionLogo: null,
      fiveSurveysLogo: null,
      heyCashLogo: null,
      user: null,
      performance: null,
      quality: null,
      quality_questions: [],
      supplier_statistics: null,
      completes_and_conversion_rates: null,
      previousBanSupplier: null,
      phone_verifications: null,
      can_trigger_verification: false,
      user_duplicates: null,
      reconciliation: {},
      countries: [],
      showEditLanguage: false,
      showEditInterfaceLanguage: false,
      isNeedApprovalToActivate: false,
      isNeedApprovalToBan: false,
      currentBuyerBans: [],
      similarUserProfilings: [],
      emailDomainFrauds: [],
      userProfilingBasics: [],
      userPayoutChanged: false,
      userMaxReconciliationBuyers: false,
      isShowAllUserDuplicates: false,
      currentCarrier: null,
      sourceExtensionLogCount: 0,
      otherAccounts: [],
      relatedAccounts: [],
      relatedAccountData: [],
      languageCodes: [],
      userInterfaceLanguage: null,
      showMinimalizeExternalId: true,
      isHighRiskEmailDomain: false,
      platformLastHundredClicks: null,
      staffReviewer: null,
      completesFactor: null
    }
  },
  computed: {
    isCurrentUserSupremeSuperAdminOrQA () {
      const currentUserId = this.$store.state.auth.AppActiveAdmin.id

      return [1, 26].includes(currentUserId)
    },
    userId () {
      return this.id ? this.id : this.$route.params.userId
    },
    emailDetails () {
      if (this.user.email) {
        const [name, domain] = this.user.email.split('@')
        return { ...this.user.email, name, domain }
      } else {
        return { ...this.user.email, name: null, domain: null }
      }
    },
    classSuspiciousResponseCode () {
      if (
        this.quality.suspicious_response_percentage > 2 &&
          this.quality.suspicious_response_count > 1
      ) return 'bg-danger text-white'
      if (this.quality.suspicious_response_percentage > 2) return 'bg-warning text-white'

      return 'bg-success text-white'
    },
    classAvgPublisherCPI () {
      if (this.quality?.click_additional_metrics && this.quality.click_additional_metrics.avg_cpi) {
        if (this.quality.click_additional_metrics.avg_cpi >= 1.3) return 'bg-danger text-white'
        if (this.quality.click_additional_metrics.avg_cpi >= 1) return 'bg-warning text-white'
        if (this.quality.click_additional_metrics.avg_cpi > 0) return 'bg-success text-white'

        return ''
      }

      return ''
    },
    classEarningPerClick () {
      if (this.quality?.click_additional_metrics && this.quality.click_additional_metrics.earnings_per_click !== null) {
        if (this.quality.click_additional_metrics.earnings_per_click < 0.6) return 'bg-success text-white'
        if (this.quality.click_additional_metrics.earnings_per_click >= 0.6 && this.quality.click_additional_metrics.earnings_per_click <= 1) return 'bg-warning text-white'
        if (this.quality.click_additional_metrics.earnings_per_click > 1) return 'bg-danger text-white'
      }
      return ''
    },
    classTimeInCompletes () {
      if (this.quality?.click_additional_metrics && this.quality.click_additional_metrics.time_in_completes_percentage !== null) {
        if (this.quality.click_additional_metrics.time_in_completes_percentage < 0.85) return 'bg-success text-white'
        if (this.quality.click_additional_metrics.time_in_completes_percentage >= 0.85 && this.quality.click_additional_metrics.time_in_completes_percentage <= 0.9) return 'bg-warning text-white'
        if (this.quality.click_additional_metrics.time_in_completes_percentage > 0.9) return 'bg-danger text-white'
      }
      return ''
    },
    classOverlappingCompletes () {
      if (this.quality?.overlap_rate) {
        const overlapRate = parseFloat(this.quality.overlap_rate)
        console.log(overlapRate, overlapRate > 0.25, 'overlapRate')
        if (overlapRate < 0.1) return 'bg-success text-white'
        if (overlapRate >= 0.1 && overlapRate <= 0.25) return 'bg-warning text-white'
        if (overlapRate > 0.25) return 'bg-danger text-white'
      }
      return ''
    },
    classTheSecondMedianWSLatency () {
      if (parseFloat(this.quality.the_second_median_ws_latency) < 100) return 'bg-success text-white'
      if (
        parseFloat(this.quality.the_second_median_ws_latency) >= 100 &&
          parseFloat(this.quality.the_second_median_ws_latency) <= 300
      ) return 'bg-warning text-white'
      if (parseFloat(this.quality.the_second_median_ws_latency) > 300) return 'bg-danger text-white'

      return ''
    },
    classTheSecondMedianTcpLatency () {
      if (parseFloat(this.quality.the_second_median_tcp_latency) < 100) return 'bg-success text-white'
      if (
        parseFloat(this.quality.the_second_median_tcp_latency) >= 100 &&
        parseFloat(this.quality.the_second_median_tcp_latency) <= 300
      ) return 'bg-warning text-white'
      if (parseFloat(this.quality.the_second_median_tcp_latency) > 300) return 'bg-danger text-white'

      return ''
    },
    classTheSecondMedianLatencyDelta () {
      if (parseFloat(this.quality.the_second_median_latency_delta) < 0 || parseFloat(this.quality.the_second_median_latency_delta) > 60) return 'bg-danger text-white'
      if (parseFloat(this.quality.the_second_median_latency_delta) < 40) return 'bg-success text-white'
      if (
        parseFloat(this.quality.the_second_median_latency_delta) >= 40 &&
          parseFloat(this.quality.the_second_median_latency_delta) <= 60
      ) return 'bg-warning text-white'

      return ''
    },
    classTheSecondLatencyResults () {
      if (parseFloat(this.quality.the_second_latency_results) > 0.5) return 'bg-success text-white'
      if (
        parseFloat(this.quality.the_second_latency_results) >= 0.2 &&
          parseFloat(this.quality.the_second_latency_results) <= 0.5
      ) return 'bg-warning text-white'
      if (parseFloat(this.quality.the_second_latency_results) < 0.2) return 'bg-danger text-white'

      return ''
    },
    classDeviceOsMismatch () {
      if (parseFloat(this.quality.device_os_mismatch_percentage) < 0.1) return 'bg-success text-white'

      return 'bg-warning text-white'
    },
    classMaxTimeDelta () {
      if (parseFloat(this.quality.max_time_delta) <= 3610) return 'bg-success text-white'

      return 'bg-warning text-white'
    },
    classTimezoneMismatch () {
      const highRiskValues = [
        'Africa/Lagos',
        'America/Mexico_City',
        'America/Caracas',
        'Asia/Karachi',
        'Asia/Damascus',
        'Asia/Shanghai'
      ]

      if (
        this.quality.timezone_mismatch.some((timezoneMismatch) => highRiskValues.includes(timezoneMismatch)
        )
      ) {
        return 'bg-danger text-white'
      }

      return 'bg-warning text-white'
    },
    classCountryMismatch () {
      const highRiskValues = [
        'Nigeria',
        'Mexico',
        'Venezuela',
        'Pakistan',
        'Syria',
        'China'
      ]

      if (
        this.quality.country_mismatch.some((countryMismatch) => highRiskValues.includes(countryMismatch)
        )
      ) {
        return 'bg-danger text-white'
      }

      return 'bg-warning text-white'
    },
    classPatternRisk () {
      if (this.quality.pattern_risk.fraud_rate_total === null) return ''
      if (
        parseFloat(this.quality.pattern_risk.fraud_rate_total ?? 0) * 100 >=
          10 ||
          parseFloat(this.quality.pattern_risk.fraud_rate_48h ?? 0) * 100 >= 10
      ) return 'bg-danger text-white'
      if (
        parseFloat(this.quality.pattern_risk.fraud_rate_total ?? 0) * 100 >=
          5 ||
          parseFloat(this.quality.pattern_risk.fraud_rate_48h ?? 0) * 100 >= 5
      ) return 'bg-warning text-white'
      if (
        parseFloat(this.quality.pattern_risk.fraud_rate_total ?? 0) * 100 < 5 &&
          parseFloat(this.quality.pattern_risk.fraud_rate_48h ?? 0) * 100 < 5
      ) return 'bg-success text-white'

      return ''
    },
    classSupplierBansQuality () {
      const supplierBansQuality =
          this.supplier_statistics?.filter(
            (supplier) => supplier.user_status === 'banned'
          ).length ?? 0

      if (supplierBansQuality === 1) return 'bg-warning text-white'
      if (supplierBansQuality >= 2) return 'bg-danger text-white'

      return 'bg-success text-white'
    },
    classRDPResult () {
      if (
        this.quality &&
          this.quality.ipqs_rdp_data &&
          this.quality.ipqs_rdp_data.rdp_usage
      ) {
        if (this.quality.ipqs_rdp_data.rdp_usage > 30) return 'bg-danger text-white'
        if (this.quality.ipqs_rdp_data.rdp_usage > 0) return 'bg-warning text-white'
        if (this.quality.ipqs_rdp_data.rdp_usage === 0) return 'bg-success text-white'
      }

      return ''
    },
    classIPQSScore () {
      if (
        this.quality &&
          this.quality.ipqs_rdp_data &&
          this.quality.ipqs_rdp_data.ipqs_unique_ips_avg
      ) {
        if (this.quality.ipqs_rdp_data.ipqs_unique_ips_avg >= 80) return 'bg-danger text-white'
        if (this.quality.ipqs_rdp_data.ipqs_unique_ips_avg > 50) return 'bg-warning text-white'
        if (this.quality.ipqs_rdp_data.ipqs_unique_ips_avg < 50) return 'bg-success text-white'
      }

      return ''
    },
    payoutAdjustmentValidate () {
      if (!this.userPayoutChanged) return null
      return (
        this.user.payout_adjustment >= 50 && this.user.payout_adjustment <= 150
      )
    },
    maxReconciliationBuyersValidate () {
      if (!this.userMaxReconciliationBuyers) return null
      return this.user.max_reconciliation_buyers <= 150
    },
    completesAndConversionsChartOptions () {
      return {
        theme: {
          mode: this.$store.state.appConfig.layout.skin
        },
        chart: {
          height: 350,
          type: 'line'
        },
        stroke: {
          width: [0, 4]
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        tooltip: {
          x: {
            format: 'dddd, dd MMM'
          }
        },
        xaxis: {
          type: 'datetime',
          categories: this.completes_and_conversion_rates.labels
        },
        yaxis: [
          {
            title: {
              text: 'Completes'
            },
            labels: {
              formatter (val) {
                return val.toFixed(0)
              }
            }
          },
          {
            opposite: true,
            title: {
              text: 'Conversion Rate'
            }
          }
        ]
      }
    },
    userDuplicate () {
      if (this.user_duplicates && !this.isShowAllUserDuplicates) {
        const IPOverlap = this.user_duplicates.filter(
          (userDuplicate) => userDuplicate.overlap === 'IP'
        )
        const OtherOverlap = this.user_duplicates.filter(
          (userDuplicate) => userDuplicate.overlap !== 'IP'
        )

        if (OtherOverlap.length < 10) {
          const showIpOverlapCount = 10 - OtherOverlap.length
          const IPOverlapShown = IPOverlap.slice(0, showIpOverlapCount)

          return {
            isShowLink: IPOverlapShown.length !== IPOverlap.length,
            IpOverlapCount: IPOverlap.length,
            data: [...OtherOverlap, ...IPOverlapShown]
          }
        }

        return {
          isShowLink: IPOverlap.length > 0,
          IpOverlapCount: IPOverlap.length,
          data: OtherOverlap
        }
      }

      return {
        isShowLink: false,
        data: this.user_duplicates
      }
    },
    classCompletesFactor () {
      // Show red background if there are alerts, regardless of total value
      if (this.completesFactor.total_alert > 0) {
        return 'bg-danger text-white'
      }

      // Apply background based on total value when no alerts are present
      if (this.completesFactor.total_actual <= 2) return '' // No color
      if (this.completesFactor.total <= 2) return 'bg-success text-white' // Green
      if (this.completesFactor.total > 2 && this.completesFactor.total <= 3) return 'bg-warning text-white' // Yellow
      if (this.completesFactor.total > 3) return 'bg-danger text-white' // Red
      return ''
    }
  },
  methods: {
    openApp () {
      const userSurveyUrl = `${window.location.origin}/?app=${this.user.app_hash}&uuid=${this.user.external_user_id}`
      window.open(userSurveyUrl, '_blank').focus()
    },
    showProtectedStatusModal () {
      this.getProtectedUserSTatusModalVue().showForm()
    },
    triggerUserVerification () {
      this.showLoading()
      this.can_trigger_verification = false
      this.$http
        .post(`/api/user-verifications/${this.userId}`)
        .then((response) => {
          const data = response.data
          this.phone_verifications = [
            ...[data],
            ...(this.phone_verifications || [])
          ]
          this.user_duplicates = [...[data], ...(this.user_duplicates || [])]
          this.hideLoading()
        })
        .catch((errors) => {
          this.hideLoading()
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    addedUserBanned (data) {
      this.user = data.response
    },
    changeStatus (fieldName) {
      this.showLoading()
      this.$http
        .post(`/api/user/${this.userId}/setting-status`, {
          field: fieldName,
          status: this.user[fieldName]
        })
        .then(() => {
          this.hideLoading()
        })
        .catch((errors) => {
          this.hideLoading()
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })

      this.$emit('update-user-settings', { field: fieldName, value: this.user[fieldName] })
    },
    addedSupplierUserBanned (data) {
      for (let i = 0; i < this.supplier_statistics.length; i++) {
        if (this.supplier_statistics[i].id === data.form.id) {
          this.supplier_statistics[i].user_status =
              data.form.type === 5 ? 'banned' : 'disabled'
          this.supplier_statistics[i].banned_reasons = data.form.comment
          this.supplier_statistics[i].banned_until = data.form.ban_indefinite ? null : this.ymdDateStrToTZDateStr(data.form.ban_expiry)
        }
      }
    },
    getSupplierBanFormVue () {
      return this.$refs.supplier_ban_form
    },
    showListUserBanModal (item) {
      this.getListUserBanModalVue().showModal(item)
    },
    showResetHashesModal (item) {
      this.getResetHashesModalVue().showModal(item)
    },
    getListUserBanModalVue () {
      return this.$refs.list_user_ban_modal
    },
    getResetHashesModalVue () {
      return this.$refs.reset_hashes_modal
    },
    showUserBuyerBans (item) {
      this.$http
        .get(`/api/user/${this.userId}/buyer-bans/${item.id}`)
        .then((response) => {
          this.$refs['user_buyer_bans_modal'].show()
          this.currentBuyerBans = response.data
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    showSimilarUserProfiling (userProfilingId) {
      this.showLoading()

      this.$http
        .get(`/api/user-profiling/${userProfilingId}/similar`)
        .then((response) => {
          this.$refs['similar_user_profiling_modal'].show()
          this.similarUserProfilings = response.data.data
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    showFraudEmailDomains (emailDomain) {
      this.$http
        .get('/api/user-profiling/fraud-email-domains', {
          params: {
            emailDomain
          }
        })
        .then((response) => {
          this.$refs['user_domain_fraud'].show()
          this.emailDomainFrauds = response.data.data
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    showRelatedAccountData () {
      this.$http
        .get(`/api/user/${this.userId}/related-account-data`)
        .then((response) => {
          this.$refs['user_related_account_data'].show()
          this.relatedAccountData = response.data.data
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    showUserProfilingBasics (userId) {
      this.$http
        .get(`/api/user-profiling/${userId}/basics`)
        .then((response) => {
          this.$refs['user_profiling_basics_modal'].show()
          this.userProfilingBasics = response.data.data
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    showUsersByCarrier (carrier) {
      this.currentCarrier = carrier
      this.$refs['usersByCarrier'].show()
    },
    getProtectedUserSTatusModalVue () {
      return this.$refs.protected_user_status
    },
    changeToReviewStatus (data) {
      this.can_trigger_verification = false
      this.phone_verifications = [
        {phone: null, verified: false},
        ...this.phone_verifications
      ]
      this.user = data.response
    },
    activatedUser (response) {
      this.user = response
    },
    async activateSupplierAgain (supplier) {
      if (
        confirm(
          'Are you sure you want to activate the supplier again for this user?'
        )
      ) {
        const supplierId = supplier.id
        this.showLoading()

        if (supplier.is_disabled_by_user) {
          await this.reactivateSupplier(supplierId)

          if (supplier.user_status === 'disabled') {
            await this.unBanSupplier(supplierId)
          }
        } else {
          await this.unBanSupplier(supplierId)
        }

        this.hideLoading()
      }
    },
    reactivateSupplier (supplierId) {
      this.$http
        .patch(`/api/user/${this.userId}/supplier/${supplierId}/activate`)
        .then((response) => {
          this.supplier_statistics = response.data.supplier_statistics
        })
        .catch((errors) => {
          this.alertError(errors.response.data)
        })
    },
    unBanSupplier (supplierId) {
      this.$http
        .delete(`/api/user/${this.userId}/ban/${supplierId}`)
        .then((response) => {
          this.supplier_statistics = response.data.supplier_statistics
        })
        .catch((errors) => {
          this.alertError(errors.response.data)
        })
    },
    showConfirmResetBirthdate () {
      if (confirm('Are you sure you want to reset birthdate this user?')) {
        this.$http
          .put(`/api/user/${this.userId}/reset-birthdate`)
          .then(() => {
            this.hideLoading()
            this.user.birthday = null
            this.alertSuccess('Birthdate has been reset')
          })
          .catch((errors) => {
            this.alertError(errors.response.data)
            this.hideLoading()
          })
      }
    },
    autoSaveComment () {
      if (this.user.org_comment === this.user.comment) return
      this.$http
        .post(`/api/user/${this.userId}/comment`, {
          comment: this.user.comment
        })
        .then((response) => {
          this.user.org_comment = this.user.comment
          this.user.comment_updated_at = response.data.comment_updated_at
          this.user.comment_updated_admin_id =
                response.data.comment_updated_admin_id
          this.user.comment_admin_name = response.data.commented_admin.name
          this.user.comment_admin_hex_color =
                response.data.commented_admin.hex_color
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    updateCountry (user, selectedOption) {
      user.country = selectedOption.code
      user.country_name = selectedOption.name
    },
    updateUserLanguages () {
      this.$http
        .put(`/api/user/${this.userId}/languages`, {
          languages: this.user.languages.map((language) => {
            return {language: language.code}
          })
        })
        .then(() => {
          this.showEditLanguage = false
          this.alertSuccess('Languages has been update')
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    updateUserInterfaceLanguage () {
      this.$http
        .post(`/api/user/${this.userId}/interface-language/${this.userInterfaceLanguage.code}`)
        .then(() => {
          this.user.interface_language = this.userInterfaceLanguage.code
          this.showEditInterfaceLanguage = false
          this.alertSuccess('Interface Language has been update')
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    updatePayoutAdjustment (value) {
      this.$http
        .put(`/api/user/${this.userId}/payout-adjustment`, {
          payout_adjustment: value
        })
        .then(() => {
          this.alertSuccess('Payout Adjustment has been update')
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    updateMinPublisherPayout (value) {
      this.$http
        .put(`/api/user/${this.userId}/min-publisher-payout`, {
          min_publisher_payout: value
        })
        .then(() => {
          this.alertSuccess('Min Publisher Payout has been update')
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    updateMaxReconciliationBuyers (value) {
      this.$http
        .put(`/api/user/${this.userId}/max-reconciliation-buyers`, {
          max_reconciliation_buyers: value
        })
        .then(() => {
          this.alertSuccess('Max Reconciliation Buyers has been update')
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    getClassVerificationDelay (delay) {
      const minutes = delay / 60

      if (minutes < 5) return 'bg-success text-white'
      if (minutes < 10) return 'bg-warning text-white'
      if (minutes >= 10) return 'bg-danger text-white'
    },
    getClassIpqsScore (score) {
      if (parseFloat(score) >= 80) return 'bg-danger text-white'
      if (parseFloat(score) > 50) return 'bg-warning text-white'
      if (parseFloat(score) < 50) return 'bg-success text-white'
    },
    getClassCarrierRisk (bannedPercentage) {
      const percent = Math.round(parseFloat(bannedPercentage) * 100)

      if (percent >= 60) return 'bg-danger text-white'
      if (percent >= 25 && percent < 60) return 'bg-warning text-white'

      return 'bg-success text-white'
    },
    getClassReconciliationPercentage (percentage) {
      if (!percentage || percentage.toFixed() < 5) return 'bg-success text-white'
      if (percentage.toFixed() > 10) return 'bg-danger text-white'

      return 'bg-warning text-white'
    },
    getClassBannedUserDuplicates (openEnd) {
      if (parseFloat(openEnd.banned_users_count_percentage) < 0.05) return 'bg-success text-white'
      if (
        parseFloat(openEnd.banned_users_count_percentage) >= 0.05 &&
          parseFloat(openEnd.banned_users_count_percentage) <= 0.2
      ) return 'bg-warning text-white'
      if (parseFloat(openEnd.banned_users_count_percentage) > 0.2) return 'bg-danger text-white'

      return ''
    },
    getConfidence (avgDaysString) {
      const avgDays = parseInt(avgDaysString)

      let text, cssClass

      if (avgDays >= 10) {
        text = 'High'
        cssClass = 'bg-success text-white'
      } else if (avgDays >= 7) {
        text = 'Medium'
        cssClass = 'bg-warning text-white'
      } else {
        text = 'Low'
        cssClass = 'bg-warning text-white'
      }

      return { text, cssClass }
    },
    resetUserDataOnSupplier (supplierId) {
      if (
        confirm(
          'Only proceed if the user has great reconciliations and overall good account standing. Only proceed if the user has confirmed he deleted his cookies.'
        )
      ) {
        this.$http
          .post(`/api/user/${this.userId}/reset-supplier/${supplierId}`)
          .then((response) => {
            this.hideLoading()
            this.supplier_statistics = response.data.supplier_statistics
          })
          .catch((errors) => {
            this.alertError(errors.response.data)
            this.hideLoading()
          })
      }
    },
    showAllUserDuplicates () {
      this.isShowAllUserDuplicates = true
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    getUserDetails () {
      const currentUserDetails =
          this.$store.getters['user/getCurrentUserDetails']

      // If the user details exist in the store, use them
      // eslint-disable-next-line eqeqeq
      if (currentUserDetails && currentUserDetails.user.id == this.userId) {
        this.performance = currentUserDetails.performance
        this.quality = currentUserDetails.quality
        this.quality_questions = currentUserDetails.quality_questions
        this.supplier_statistics = currentUserDetails.supplier_statistics
        this.user = currentUserDetails.user
        this.user.org_comment = currentUserDetails.user.comment
        this.phone_verifications = currentUserDetails.phone_verifications
        this.user_duplicates = currentUserDetails.user_duplicates
        this.can_trigger_verification =
            currentUserDetails.can_trigger_verification
        this.reconciliation = currentUserDetails.reconciliation
        this.completes_and_conversion_rates =
            currentUserDetails.completes_and_conversion_rates_thirty_days_ago
        this.sourceExtensionLogCount = currentUserDetails.extension_logs_count
        this.otherAccounts = currentUserDetails.other_accounts
        this.relatedAccounts.push(currentUserDetails.related_accounts)
        this.languageCodes = currentUserDetails.language_codes
        this.isHighRiskEmailDomain = currentUserDetails.is_high_risk_email_domain
        this.platformLastHundredClicks = currentUserDetails.platform_last_100_clicks
        this.staffReviewer = currentUserDetails.staff_reviewer
        this.completesFactor = currentUserDetails.completes_factor
      } else {
        this.showLoading()

        this.$http
          .get(`/api/user/${this.userId}`)
          .then((response) => {
            this.hideLoading()
            const returnData = response.data

            this.performance = returnData.performance
            this.quality = returnData.quality
            this.quality_questions = returnData.quality_questions
            this.supplier_statistics = returnData.supplier_statistics
            this.user = returnData.user
            this.user.org_comment = this.user.comment
            this.phone_verifications = returnData.phone_verifications
            this.user_duplicates = returnData.user_duplicates
            this.can_trigger_verification = returnData.can_trigger_verification
            this.reconciliation = returnData.reconciliation
            this.completes_and_conversion_rates =
                  returnData.completes_and_conversion_rates_thirty_days_ago
            this.sourceExtensionLogCount = returnData.extension_logs_count
            this.otherAccounts = returnData.other_accounts
            this.relatedAccounts.push(returnData.related_accounts)
            this.languageCodes = returnData.language_codes
            this.isHighRiskEmailDomain = returnData.is_high_risk_email_domain
            this.platformLastHundredClicks = returnData.platform_last_100_clicks
            this.staffReviewer = returnData.staff_reviewer
            this.completesFactor = returnData.completes_factor

            // Save the user details in Vuex store
            this.$store.commit('user/SET_CURRENT_USER_DETAIL', returnData)
          })
          .catch((errors) => {
            this.alertError(errors.response.data)
            this.hideLoading()
          })
      }
    },
    getSupplierFactorClass (item) {
      if (item.actual_completes <= 2) return '' // No color if completes <= 2
      if (item.factor <= 2) return 'bg-success text-white' // Green if factor <= 2
      if (item.factor > 2 && item.factor <= 3) return 'bg-warning text-white' // Yellow if factor > 2 and <= 3
      if (item.factor > 3) return 'bg-danger text-white' // Red if factor > 3
      return '' // Default no color
    },
    setQuickLinkProperties () {
      store.commit('app/SET_QUICK_LINK_PROPERTIES', {
        display: true,
        id: this.id,
        project: 'ps',
        model: 'user'
      })
    }
  },
  watch: {
    '$store.state.chat.userDetailsInfo.ip_location_check': {
      handler (value) {
        this.user.ip_location_check = value
      }
    },
    '$route.params.userId' () {
      this.$router.go()
    },
    'user.status' (val) {
      if (this.status) {
        this.$emit('update:status', val)
      }
    },
    'shownAsModal': {
      handler (status) {
        if (status) {
          this.setQuickLinkProperties()
        }
      },
      immediate: true
    }
  },
  created () {
    this.getUserDetails()
  },
  mounted () {
    this.primeOpinionLogo = $themeConfig.app.appLogoImage
    this.fiveSurveysLogo = $themeConfig.app.fiveSurvesLogoImage
    this.heyCashLogo = $themeConfig.app.heyCashLogoImage

    this.$http
      .get('/api/countries', {
        params: {
          limit: -1
        }
      })
      .then((response) => {
        this.countries = response.data
      })
      .catch((errors) => {
        if (errors && errors.response && errors.response.data) {
          this.alertError(errors.response.data.errors)
        }
      })
  }
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
