<template>
  <base-table
      :fields="fields"
      :filterSettings="filterSettings"
      :url="`/api/user/users-by-carrier/${carrier}`"
  >
    <template #tableHeader>
      <h4 class="text-center">Users {{ carrier }}</h4>
    </template>

    <template #cell(id)="item">
      <UserElement :id="item.value" :status="item.row.status"/>
    </template>
    <template #cell(status)="item">
      <UserStatusIdBadge :status_id="item.value" />
    </template>
  </base-table>
</template>

<script>
import UserStatusIdBadge from '@/views/users/UserDetails/UserStatusIdBadge.vue'

export default {
  components: {UserStatusIdBadge},
  props: ['carrier'],
  data () {
    return {
      fields: [
        {
          label: 'ID',
          key: 'id',
          sortable: true
        },
        {
          label: 'STATUS',
          key: 'status',
          sortable: true
        }
      ],
      filterSettings: {}
    }
  }
}
</script>
