<template>
    <table class="table b-table table-striped table-hover" v-if="userBuyerBans">
      <thead>
      <tr>
        <th>Buyer ID</th>
        <th>Buyer Name</th>
        <th>Type</th>
        <th>Created</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="userBuyerBan in userBuyerBans" :key="userBuyerBan.id">
        <td>{{ userBuyerBan.id }}</td>
        <td>{{ userBuyerBan.supplier_buyer_name }} ({{ userBuyerBan.supplier_buyer_id }})</td>
        <td>
          <span>{{ userBuyerBan.type_name }}</span>
        </td>
        <td>{{ userBuyerBan.created_at }}</td>
      </tr>
      </tbody>
    </table>
</template>

<script>
export default {
  props: {
    userBuyerBans: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style>

</style>
