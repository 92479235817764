<template>
  <div>
    <h3 v-if="!isDisplayInline">Actions</h3>
    <div :class="{'d-flex': isDisplayInline}">
      <button
          class="btn btn-danger"
          @click="openUserBanModal(2)"
          type="button"
          :class="isDisplayInline ? 'mr-1' : 'mb-1'"
      >
        Ban
      </button>
      <br/>
      <button
          class="btn text-white user-status-3 text-nowrap"
          @click="openUserBanModal(3)"
          type="button"
          :class="isDisplayInline ? 'mr-1' : 'mb-1'"
      >
        Shadow Ban
      </button>
      <br/>
      <button
          class="btn text-white user-status-6 text-nowrap"
          @click="openUserBanModal(6)"
          type="button"
          :class="isDisplayInline ? 'mr-1' : 'mb-1'"
      >
        On Hold
      </button>
      <br/>
      <button v-if="user.status !== 4"
          class="btn btn-secondary"
          @click="openReviewConfirmModal"
          type="button"
      >
        Review
      </button>
<!--      <button v-if="can_trigger_verification"-->
<!--              class="btn btn-secondary d-block mt-1"-->
<!--              @click="triggerUserVerification"-->
<!--              type="button"-->
<!--      >-->
<!--          SMS verification-->
<!--      </button>-->
      <br v-if="isCanUpdateGoodwillCredit" />
      <button v-if="isCanUpdateGoodwillCredit"
              class="btn btn-success"
              :class="isDisplayInline ? 'ml-1' : 'mt-1'"
              @click="openGoodwillCreditModal"
              type="button"
      >
        Credit
      </button>
    </div>

    <UserBanModal :isNeedApproval="false" :userId="user.id" :status="currentStatusBan" ref="user_ban_form" @added="addedUserBanned" />
    <ConfirmReviewModal ref="confirm_review_modal" :userId="user.id" @changed="changeToReviewStatus" />
    <GoodwillCreditModal ref="goodwill_credit_modal" :exchangeRate="exchangeRate" :currencyName="currencyName" :userId="user.id" />
  </div>
</template>

<script>
import ConfirmReviewModal from '@/views/users/UserDetails/ConfirmReviewModal.vue'
import UserBanModal from '@/views/users/UserDetails/UserBanModal.vue'
import GoodwillCreditModal from '@/views/users/UserDetails/GoodwillCreditModal.vue'

export default {
  name: 'UserActionsBox',
  components: {GoodwillCreditModal, UserBanModal, ConfirmReviewModal},
  props: {
    user: {
      type: Object,
      required: true
    },
    can_trigger_verification: {
      type: Boolean
    },
    phone_verifications: {
      type: Array
    },
    isDisplayInline: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      exchangeRate: null,
      currencyName: 'Points',
      currentStatusBan: 2
    }
  },
  computed: {
    isCanUpdateGoodwillCredit () {
      const currentUserId = this.$store.state.auth.AppActiveAdmin.id

      return [1, 26].includes(currentUserId)
    }
  },
  methods: {
    openUserBanModal (status) {
      this.currentStatusBan = status
      this.getUserBanFormVue().showForm()
    },
    openReviewConfirmModal () {
      this.getConfirmReviewModalVue().showForm()
    },
    openGoodwillCreditModal () {
      this.showLoading()
      this.$http.get(`/api/apps/${this.user.app_id}/exchange-rate`)
        .then((response) => {
          this.hideLoading()

          this.exchangeRate = response.data.exchange_rate
          this.currencyName = response.data.currency_name
          this.getGoodwillCreditModalVue().showForm()
        }).catch((errors) => {
          this.hideLoading()
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
            this.alertError(errors.response.data.errors)
          }
        })
    },
    getUserBanFormVue () {
      return this.$refs.user_ban_form
    },
    getConfirmReviewModalVue () {
      return this.$refs.confirm_review_modal
    },
    getGoodwillCreditModalVue () {
      return this.$refs.goodwill_credit_modal
    },
    addedUserBanned (data) {
      this.$emit('update:user', data.response)
    },
    changeToReviewStatus (data) {
      const newPhoneVerifications = [
        {phone: null, verified: false},
        ...this.phone_verifications
      ]

      this.$emit('update:can_trigger_verification', false)
      this.$emit('update:phone_verifications', newPhoneVerifications)
      this.$emit('update:user', data.response)
    }
    // triggerUserVerification () {
    //   this.showLoading()
    //   this.can_trigger_verification = false
    //   this.$http.post(
    //       `/api/user-verifications/${this.userId}`
    //   ).then((response) => {
    //     const data = response.data
    //     this.phone_verifications = [
    //       ...[data],
    //       ...this.phone_verifications || []
    //     ]
    //     this.user_duplicates = [
    //       ...[data],
    //       ...this.user_duplicates || []
    //     ]
    //     this.hideLoading()
    //   }).catch((errors) => {
    //     this.hideLoading()
    //     if (errors && errors.response && errors.response.data) {
    //       this.alertError(errors.response.data.errors)
    //     }
    //   })
    // },
  }
}
</script>
