import itemSchema from '@/store/modules/schemas/default.schema'
import ModuleBase from '@/store/modules/ModuleBase'
import {cloneDeep} from 'lodash/lang'
import axios from '@/main/axios'

const resourceName = 'qualification_questions'
const base = ModuleBase(itemSchema, resourceName)
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
    all_marked_as_master: [],
    pagination_marked_as_master: {
      current_page: 0,
      per_page: 0,
      last_page: 0,
      from: 0,
      to: 0,
      total: 0,
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      prev_page_url: '',
      path: ''
    }
  },
  getters: {
    ...base.getters,
    allMarkedAsMaster: state => state.all_marked_as_master,
    paginationMarkedAsMaster: state => state.pagination_marked_as_master
  },
  mutations: {
    ...base.mutations,
    ALL_MARKED_AS_MASTER (state, items) {
      state.all_marked_as_master = items
    },
    PAGINATION_MARKED_AS_MASTER (state, pagination) {
      state.pagination_marked_as_master = pagination
    }
  },
  actions: {
    ...base.actions,
    getMarkedAsMaster: async ({commit}, searchParams) => {
      const params = base.buildQuerySearch(searchParams)
      const res = await axios.get(base.basePath + resourceName, {params})
      let listItems = res.status === 200 && res.data && res.data.data ? res.data.data : []
      if (parseInt(params.limit) === -1) listItems = res.status === 200 && res.data ? res.data : []
      await commit('ALL_MARKED_AS_MASTER', listItems)
      let pagination = {
        current_page: 1,
        per_page: listItems.length,
        last_page: 1,
        from: 0,
        to: listItems.length - 1,
        total: listItems.length,
        first_page_url: '',
        last_page_url: '',
        next_page_url: '',
        prev_page_url: '',
        path: ''
      }
      if (params.limit !== -1) {
        pagination = cloneDeep(res.data)
        delete pagination.data
      }
      await commit('PAGINATION_MARKED_AS_MASTER', pagination)
      return res
    },
    storeMapping: async ({commit}, items) => {
      return await axios.post(`${base.basePath + resourceName  }/store-mapping`, items)
    },
    editMapping: async ({commit}, items) => {
      return await axios.post(`${base.basePath + resourceName  }/edit-mapping`, items)
    },
    removeMapping: async ({commit}, items) => {
      return await axios.post(`${base.basePath + resourceName  }/remove-mapping`, items)
    }
  }
}
