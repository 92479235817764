<template>
    <b-modal
        :id="modalUUid"
        :ref="modalUUid"
        title="Add user ban"
        :hide-footer="true"
    >
        <BaseForm
            ref="main_form"
            :settings="userBanFormSettings"
            @success="successAddedUserBan"
            @cancel="$bvModal.hide(modalUUid)"
            v-slot="{ save, cancel }"
        >
            <b-row class="mb-1" style="display: flex; align-items: center">
                <b-col cols="3"> Ban Expiry </b-col>
                <b-col
                    cols="9"
                    style="display: flex; align-items: center; gap: 20px"
                >
                    <FormElement
                        ref="ban_indefinite"
                        style="margin-bottom: 0px"
                        :elementSettings="userBanFormSettings.formElements.ban_indefinite"
                        :formSettings="userBanFormSettings"
                    />
                    <FormElement
                        ref="ban_expiry"
                        style="margin-bottom: 0px"
                        :elementSettings="userBanFormSettings.formElements.ban_expiry"
                        :formSettings="userBanFormSettings"
                    />
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col cols="3"> Internal Comment </b-col>
                <b-col cols="9">
                    <FormElement
                        ref="comment"
                        :elementSettings="userBanFormSettings.formElements.comment"
                        :formSettings="userBanFormSettings"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button variant="primary" @click="save()" class="mr-1">
                        Save
                    </b-button>
                    <b-button variant="warning" @click="cancel()"
                        >Cancel</b-button
                    >
                </b-col>
            </b-row>
        </BaseForm>
    </b-modal>
</template>

<script>
export default {
  props: {
    userId: {
      required: true
    }
  },
  data () {
    return {
      modalUUid: `user-supplier-ban-modal${ this.userId }`,
      refreshKey: 'user-supplier-ban-modal',
      userBanFormSettings: {
        addUrl: `/api/user/${  this.userId  }/supplier-ban`,
        primaryKey: 'id',
        formElements: {
          id: {
            type: 'input',
            sub_type: 'hidden',
            defaultValue: 0,
            rules: [],
            post_param: 'id',
            value: '',
            errorClass: 'input-error',
            errorMessage: '',
            isValidated: false,
            isError: false
          },
          type: {
            type: 'input',
            sub_type: 'hidden',
            defaultValue: 5, //MANUALLY BAN BY ADMIN
            rules: [],
            post_param: 'type',
            value: 5, //MANUALLY BAN BY ADMIN
            errorMessage: '',
            isValidated: false,
            isError: false
          },
          ban_indefinite: {
            type: 'checkbox_yes_no',
            post_param: 'action',
            value: false,
            defaultValue: false,
            data: {
              text: 'Indefinite'
            },
            errorClass: 'input-error',
            isValidated: false,
            isError: false,
            post_param: 'ban_indefinite',
            event: {
              change ($event, elementSettings, formSettings) {
                formSettings.formElements.ban_expiry.value = null
              }
            }
          },
          ban_expiry: {
            type: 'date',
            defaultValue: null,
            post_param: 'action',
            value: null,
            errorClass: 'input-error',
            errorMessage: 'Ban expiry is invalid!',
            isValidated: false,
            isError: false,
            refreshKey: 'ban_expiry',
            validate (elementSettings, formSettings) {
              if (formSettings.formElements.ban_indefinite.value) return true
              return formSettings.formElements.ban_expiry.value !== null
            },
            post_param: 'ban_expiry',
            event: {
              input ($event, elementSettings, formSettings) {
                formSettings.formElements.ban_indefinite.value = false
              }
            }
          },
          comment: {
            type: 'textarea',
            defaultValue: '',
            rules: ['required'],
            post_param: 'comment',
            value: '',
            errorClass: 'input-error',
            errorMessage: 'Internal comment is required!',
            isValidated: false,
            place_holder: 'Input internal comment',
            isError: false
          }
        },
        vueContainer: this
      }
    }
  },
  methods: {
    showForm (supplierId, type) {
      this.$bvModal.show(this.modalUUid)

      this.$nextTick(() => {
        this.getFormVue().clearForm()
        this.userBanFormSettings.formElements.id.value = supplierId
        this.userBanFormSettings.formElements.type.value = type
      })

    },
    successAddedUserBan (data) {
      this.$emit('added', data)
      this.$bvModal.hide(this.modalUUid)
    },
    getFormVue () {
      return this.$refs.main_form
    }
  }
}
</script>

<style>
</style>
