import itemSchema from '@/store/modules/schemas/default.schema'
import ModuleBase from '@/store/modules/ModuleBase'
import {cloneDeep} from 'lodash/lang'
import axios from '@/main/axios'

const resourceName = 'qualification_questions'
const base = ModuleBase(itemSchema, resourceName)
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state
  },
  getters: {
    ...base.getters
  },
  mutations: {
    ...base.mutations,
    ADD_ANSWER (state, item) {
      let existedItem = state.all.filter(i => i.id === item.question_id)
      if (existedItem.length) {
        const itemIndex = state.all.indexOf(existedItem[0])
        const all = cloneDeep(state.all)
        existedItem = existedItem[0]
        if (!existedItem.answers) existedItem.answers = []
        existedItem.answers.push(item)
        all[itemIndex] = existedItem
        state.all = all
      } else console.log('item_not_found', item)
    }
  },
  actions: {
    ...base.actions,
    addAnswer: async ({commit}, item) => {
      return await commit('ADD_ANSWER', item)
    },
    mapCombinedQuestionAndAnswers: async ({commit}, items) => {
      const res = await axios.post(`${base.basePath + resourceName  }/map-combined-question-and-answers`, items)
      if (res.status === 200 && res.data && res.data.data) {
        console.log('update result:', res.data.data)
      }
      return res
    }
  }
}
