import itemSchema from '@/store/modules/schemas/default.schema'
import ModuleBase from '@/store/modules/ModuleBase'
import axios from '@/main/axios'

const resourceName = 'click-tickets'
const base = ModuleBase(itemSchema, resourceName)
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
    ...{
      notReviewedCount: 0
    }
  },
  getters: {
    ...base.getters,
    notReviewedCount: state => state.notReviewedCount
  },
  mutations: {
    ...base.mutations,
    SET_NOT_REVIEWED_COUNT (state, value) {
      state.notReviewedCount = value
    }
  },
  actions: {
    ...base.actions,
    getNotReviewedCount: async (state) => {
      const url = `${ base.basePath + resourceName }/not-reviewed-count`
      const { data } = await axios.get(url)
      state.commit('SET_NOT_REVIEWED_COUNT', data.count)
    }
  }
}
