<template>
  <div>
        <b-form-checkbox
            :class="errorClasses"
            :value="typeof elementSettings.trueValue !== 'undefined' ? elementSettings.trueValue : true"
            :unchecked-value="typeof elementSettings.falseValue !== 'undefined' ? elementSettings.falseValue: false"
            :disabled="elementSettings.disabled"
            v-model="elementSettings.value"
            @change="change"
        >
            {{elementSettings.data.text}}
        </b-form-checkbox>
        <div class="text-error-notification" v-if="isError && typeof elementSettings.errorMessage === 'string' && elementSettings.isValidated">{{elementSettings.errorMessage}}</div>
  </div>
</template>
<script>
import {
  BFormCheckbox
} from 'bootstrap-vue'
export default {
  components: {
    BFormCheckbox
  },
  props: {
    elementSettings: {
      required: true,
      type: Object
    },
    formSettings: {
      required: true,
      type: Object
    }
  },
  methods: {
    executeElementValidate () {
      this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
    },
    change ($event) {
      if (this.elementSettings.event && typeof this.elementSettings.event.change) {
        this.elementSettings.event.change($event, this.elementSettings, this.formSettings)
      }
    }
  },
  computed: {
    isError: {
      cache: false,
      get () {
        this.elementSettings.isError = !this.validate(this.elementSettings, this.formSettings)
        return this.elementSettings.isError
      }
    },
    errorClasses: {
      cache: false,
      get () {
        if (typeof this.elementSettings.errorClasses === 'string') {
          if (this.elementSettings.isValidated && this.isError) {
            return this.elementSettings.errorClasses
          }
          return ''
        } else {
          return ''
        }
      }
    }
  }
}
</script>
