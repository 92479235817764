<template>
  <div class="user-badge-name d-flex align-items-center">
    <div class="user-badge-name__first-letter" :style="`background-color:${color};`">
      {{ firstLetterUserName }}
    </div>
    <div>{{ name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'red'
    }
  },
  computed: {
    firstLetterUserName () {
      return this.name.charAt(0)
    }
  }
}
</script>

<style lang="scss">
.user-badge-name {
  gap: 5px;

  &__first-letter {
    display: grid;
    align-items: center;
    font-size: 16px;
    color: white;
    width: 22px;
    height: 22px;
    font-weight: 600;
    text-align: center;
  }

}
</style>
