<template>
  <b-modal
      :id="modalUuid"
      :ref="modalUuid"
      title="All user ban"
      :hide-footer="true"
      size="lg"
  >
    <table class="table b-table table-striped table-hover" v-if="userBanSupplier">
      <thead>
      <tr>
        <th>Supplier</th>
        <th>Reason</th>
        <th>Started Date</th>
        <th>Ended Date</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, key) in userBanSupplier.user_bans" :key="key">
        <td>
          <SupplierElement :name="userBanSupplier.name" :id="item.supplier_id" />
        </td>
        <td>{{ item.reasons }}</td>
        <td>{{ item.created_at | dotDateTime }}</td>
        <td>{{ item.end_at | dotDateTime }}</td>
      </tr>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      required: true
    }
  },
  data () {
    return {
      modalUuid: `list-user-ban-modal${ this.id }`,
      userBanSupplier: null
    }
  },
  methods: {
    showModal (data) {
      this.userBanSupplier = data
      this.$bvModal.show(this.modalUuid)
    }
  }
}
</script>
